import { ApolloClient } from '@apollo/client'
import {
  CREATE_ANALYTICS_PIPELINE,
  DELETE_ANALYTICS_PIPELINE,
  GET_ANALYTICS_PIPELINE,
  GET_ATTRIBUTES,
  GET_DATA_AND_ANALYTICS_PIPELINES,
  GET_DATA_INGESTIONS,
  TRIGGER_ANALYTICS_PIPELINE,
} from '@engine-b/integration-engine/data/data-ingestion-api'
import { Query } from '@engine-b/shared/types'
import {
  SliceCaseReducers,
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit'
import { INITIAL_DIGITAL_AUDIT_ANALYTICS } from './state'
import { DigitalAuditAnalytics, Node } from './typedefs'
import axios from 'axios'
import cuid from 'cuid'
import {
  COUNT,
  DUPLICATE_ROW,
  EXTRACT,
  FILTER,
  LOAD,
} from '@engine-b/integration-engine/features/audit-analytics'

export const digitalAuditStateSlice = createAuditSlice(
  INITIAL_DIGITAL_AUDIT_ANALYTICS
)

// Get analytics pipline by id
export const getAnalyticsPipeline = createAsyncThunk(
  'digitalAuditAnalytics/getAnalyticsPipeline',
  async (
    { id, client }: { id: string | undefined; client: ApolloClient<unknown> },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.query<{
        getAnalyticsPipeline: Query['getAnalyticsPipeline']
      }>({
        query: GET_ANALYTICS_PIPELINE,
        variables: { id },
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Get All Data & Analytics Pipelines
export const getAnalyticsPipelines = createAsyncThunk(
  'digitalAuditAnalytics/getAnalyticsPipelines',
  async (
    {
      client,
      page,
      results,
      auditedEntityId,
      engagementId,
      status,
      pipelineName,
    }: {
      client: ApolloClient<unknown>
      page: number
      results: number
      auditedEntityId?: string
      engagementId?: string
      pipelineName?: string
      status?: string
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.query({
        query: GET_DATA_AND_ANALYTICS_PIPELINES,
        variables: {
          page,
          results,
          ...(auditedEntityId && { auditedEntityId }),
          ...(engagementId && { engagementId }),
          ...(status && status !== 'All' && { status }),
          ...(pipelineName && { pipelineName }),
        },
        fetchPolicy: 'no-cache',
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Get cdm output file columns for a particular node
export const getCdmOutputColumns = createAsyncThunk(
  'digitalAuditAnalytics/getCdmOutputColumns',
  async (
    payload: {
      client: ApolloClient<unknown>
      entityName: string
      auditFirm: string
    },
    { rejectWithValue }
  ) => {
    try {
      const { client, entityName } = payload
      const response = await client.query({
        query: GET_ATTRIBUTES,
        variables: {
          entityName,
        },
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// get output columns by using dry run
export const getOutputColumns = createAsyncThunk(
  'digitalAuditAnalytics/getOutputColumns',
  async (
    { data, token }: { data: any; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env['NX_CUSTOM_MAPPER_API_URL']}/analytics/dry-run`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Create Data & Analytics Pipeline
export const createAnalyticsPipeline = createAsyncThunk(
  'digitalAuditAnalytics/createAnalyticsPipeline',
  async (
    {
      client,
      name,
      engagementId,
    }: { client: ApolloClient<unknown>; name: string; engagementId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_ANALYTICS_PIPELINE,
        variables: {
          name,
          engagementId,
        },
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// get Analytics pipeline summary
export const getAnalyticsPipelineSummary = createAsyncThunk(
  'digitalAuditAnalytics/getAnalyticsPipelineSummary',
  async (
    { data, token }: { data: any; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env['NX_CUSTOM_MAPPER_API_URL']}/analytics/pipeline/summary`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// get Analytics pipeline summary
export const saveAnalyticsPipelineMap = createAsyncThunk(
  'digitalAuditAnalytics/saveAnalyticsPipelineMap',
  async (
    { data, token }: { data: any; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env['NX_CUSTOM_MAPPER_API_URL']}/analytics/save`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// Create Data & Analytics Pipeline
export const triggerAnalyticsPipeline = createAsyncThunk(
  'digitalAuditAnalytics/triggerAnalyticsPipeline',
  async (
    {
      client,
      id,
      path,
    }: { client: ApolloClient<unknown>; id: string; path: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: TRIGGER_ANALYTICS_PIPELINE,
        variables: {
          triggerAnalyticsPipelineId: id,
          pipelineMapPath: path,
        },
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const getDataIngestionsDNA = createAsyncThunk(
  'digitalAuditAnalytics/getDataIngestions',
  async (options: {
    client: ApolloClient<unknown>
    page?: number
    pageSize?: number
    auditedEntityId?: string
    engagementId?: string
  }) => {
    const { client, page, pageSize, auditedEntityId, engagementId } = options
    const response = await client.query<{
      dataIngestions: Query['dataIngestions']
    }>({
      query: GET_DATA_INGESTIONS,
      variables: {
        page,
        pageSize,
        auditedEntityId,
        engagementId,
        status: 'COMPLETED',
      },
    })
    return response
  }
)

// Delete Analytics Pipeline by given id
export const deleteAnalyticsPipeline = createAsyncThunk(
  'digitalAuditAnalytics/deleteAnalyticsPipeline',
  async (
    {
      client,
      pipelineId,
    }: { client: ApolloClient<unknown>; pipelineId: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await client.mutate({
        mutation: DELETE_ANALYTICS_PIPELINE,
        variables: {
          deleteAnalyticsPipelineId: pipelineId,
        },
      })
      return response
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// get Saved Pipelines Map path list
export const getSavedPipelinesPath = createAsyncThunk(
  'digitalAuditAnalytics/getSavedPipelinesPath',
  async (
    { data, token }: { data: any; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env['NX_CUSTOM_MAPPER_API_URL']}/analytics/list`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// get Saved Pipeline Map json file data
export const getSavedPipelineMap = createAsyncThunk(
  'digitalAuditAnalytics/getSavedPipelineMap',
  async (
    { data, token }: { data: any; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env['NX_CUSTOM_MAPPER_API_URL']}/analytics/load`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// get Analytics pipeline node output
export const getAnalyticsPipelineNodeOutput = createAsyncThunk(
  'digitalAuditAnalytics/getAnalyticsPipelineNodeOutput',
  async (
    { file_path, token }: { file_path: string; token: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env['NX_CUSTOM_MAPPER_API_URL']}/analytics/pipeline/records`,
        { file_path },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

function createAuditSlice(initialState: DigitalAuditAnalytics) {
  return createSlice<
    DigitalAuditAnalytics,
    SliceCaseReducers<DigitalAuditAnalytics>
  >({
    name: 'digitalAuditAnalytics',
    initialState,
    reducers: {
      handleBasicFormDetails: (state, { payload }) => {
        const { name, value } = payload
        state[name] = value
        if (['clientDetails', 'engagementDetails'].includes(name)) {
          state.dataIngestions = []
        }
        return state
      },
      addNode: (state, { payload }) => {
        state.nodes = state.nodes.concat(payload?.node)
        state.activeNodeIndx = state.nodes.length - 1
      },
      removeNode: (state, { payload }) => {
        state.nodes = state.nodes.filter((node) => node.id !== payload?.nodeId)
      },
      removeEdge: (state, { payload }) => {
        state.edges = state.edges.filter(
          (edge) => !payload.edgeIds.includes(edge.id)
        )
      },
      modifyEdge: (state, { payload }) => {
        state.edges = state.edges.map((edge) => {
          if (edge.id === payload.id) {
            return payload
          }
          return edge
        })
      },
      modifyNodePosition: (state, { payload }) => {
        state.nodes = state.nodes.map((n) => {
          if (n.id === payload?.node?.id) {
            n.position = payload?.node?.position
          }
          return n
        })
      },
      addEdges: (state, { payload }) => {
        // on Edge connection supply source output columns to target input columns
        const sourceIndex = state.nodes.findIndex(
          (node) => node.id === payload?.edge?.source
        )
        const targetIndex = state.nodes.findIndex(
          (node) => node.id === payload?.edge?.target
        )

        if (
          sourceIndex > -1 &&
          targetIndex > -1 &&
          state.nodes[sourceIndex].data.output_columns
        ) {
          if (state.nodes[targetIndex].data.operation_name === DUPLICATE_ROW) {
            state.zeroConfigNodeIndex = targetIndex
          }
          state.nodes[targetIndex].data['input_columns'] =
            state.nodes[sourceIndex].data.output_columns
        }

        state.edges = state.edges.concat(payload?.edge)
      },
      resetDigitalAuditAnalytics: () => {
        return INITIAL_DIGITAL_AUDIT_ANALYTICS
      },
      handleColumnChange: (state, { payload }) => {
        const index = state.nodes.findIndex((node) => node.id === payload.id)
        if (state.nodes[index]?.data.operation_name === DUPLICATE_ROW) {
          state.zeroConfigNodeIndex = index
        }
        if (payload.value !== null) {
          if (Array.isArray(payload.value) && payload.value.length === 0) {
            delete state.nodes[index]?.data[payload.key]
          } else {
            if (payload.key === 'ingestion') {
              delete state.nodes[index].data.cdm_file
            } else if (payload.key === 'aggregation_type') {
              if (payload?.value?.value === 'count') {
                state.nodes[index].data['unique'] = false
              } else {
                delete state.nodes[index].data.unique
              }
            } else if (payload.key === 'bkd_value') {
              state.nodes[index].data['is_bkd_custom'] =
                payload.value.title === 'Custom Input'
            } else if (
              payload.key === 'column_name' &&
              state.nodes[index].data.operation_name === FILTER
            ) {
              delete state.nodes[index].data.operation_formula
              delete state.nodes[index].data.operation_value
              delete state.nodes[index].data.filter_value
            }

            state.nodes[index].data[payload.key] = payload.value
          }
        } else {
          delete state.nodes[index].data[payload.key]
          // If key belongs to extract nodes then delete dependant properties too
          if (['ingestion', 'cdm_file'].includes(payload.key)) {
            if (payload.key === 'ingestion') {
              delete state.nodes[index].data.cdm_file
              delete state.nodes[index].data.output_columns
              delete state.nodes[index].data.file_name
              delete state.nodes[index].data.file_path
            } else {
              delete state.nodes[index].data.output_columns
              delete state.nodes[index].data.file_name
              delete state.nodes[index].data.file_path
            }
          }
          // Clear the unique property when aggregation_type is set to null
          if (payload.key === 'aggregation_type')
            delete state.nodes[index].data.unique
        }

        // Output file name should not be the same
        const data = [...state.nodes]

        if (data[index].data.type === LOAD) {
          data.forEach((node: Node, i: number) => {
            if (node.data.type === LOAD) {
              data[i].data.loadFileNameError =
                data.filter(
                  (item: Node) =>
                    ![undefined, '', null].includes(node.data?.file_name) &&
                    item.data?.file_name?.trim() ===
                      node.data?.file_name?.trim()
                ).length > 1
            }
          })
        }
      },
      updateActiveNodeIndex: (state, { payload }) => {
        state.activeNodeIndx = payload.index
      },
      toggleConfigPanel: (state) => {
        state.configPanel = !state.configPanel
      },
      handleNodeEdges: (state, { payload }) => {
        state.nodes = payload.nodes
        state.edges = payload.edges
      },
      /**
       * This function is used to reset nodes when source output columns changes
       */
      resetNode: (state, { payload }) => {
        const nodeIndex = state.nodes.findIndex((node) => node.id === payload)
        if (nodeIndex > -1) {
          const {
            id,
            position,
            data: { label, operation_name, type, file_name },
          } = state.nodes[nodeIndex]
          state.nodes[nodeIndex] = {
            id,
            position,
            data: {
              label,
              operation_name,
              type,
              // While resetting nodes avoid nodes with type load
              ...(type === LOAD && { file_name }),
              ...(operation_name === COUNT && { unique: false }),
            },
          }
        }
      },
      toggleLoadMap: (state) => {
        state.loadingMap = !state.loadingMap
      },
    },
    extraReducers: (builder) => {
      builder.addCase(getAnalyticsPipeline.pending, (state) => {
        state.loader = true
      })
      builder.addCase(getAnalyticsPipeline.fulfilled, (state, action) => {
        state.analyticsPipeline = action.payload.data.getAnalyticsPipeline
        state.loader = false
      })
      builder.addCase(getAnalyticsPipeline.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(getAnalyticsPipelines.pending, (state) => {
        state.loader = true
      })
      builder.addCase(getAnalyticsPipelines.fulfilled, (state, { payload }) => {
        state.analyticsPipelines =
          payload.data.analyticsPipelines.pipelines.map((pipeline) => ({
            ...pipeline,
            auditedEntity: pipeline.engagement.auditedEntity.name,
          }))
        state.totalPipelines = payload.data.analyticsPipelines.total
        // Reset summary and create pipeline page data
        state.analyticsPipeline = null
        state.nodes = []
        state.edges = []
        state.loader = false
      })
      builder.addCase(getAnalyticsPipelines.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(getCdmOutputColumns.pending, (state) => {
        state.loader = true
      })
      builder.addCase(
        getCdmOutputColumns.fulfilled,
        (state, { payload, meta }) => {
          /**
           * When cdm file columns are fetched then update the following properties
           * output_columns
           * file_name
           * file_path
           */
          const { auditFirm } = meta.arg
          const data = state.nodes[state.activeNodeIndx].data
          data['output_columns'] = payload.data.attributes.map((attr) => ({
            title: attr.name,
            value: attr.name,
            data_type: attr.dataType,
          }))
          data['file_name'] = data.cdm_file.systemName + '.csv'
          const { sharePath } = data.ingestion.container
          data[
            'file_path'
          ] = `https://${process.env['NX_ADF_STORAGE_ACCOUNT_NAME']}.blob.core.windows.net/${auditFirm}/${sharePath}/zip/cdm.zip`
          state.loader = false
        }
      )
      builder.addCase(getCdmOutputColumns.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(getOutputColumns.pending, (state) => {
        state.loader = true
      })
      builder.addCase(
        getOutputColumns.fulfilled,
        (state, { payload, meta: { arg } }) => {
          const index = state.nodes.findIndex(
            (node: Node) => node.id === arg.data.id
          )
          const data = state.nodes[index].data
          data['output_columns'] = payload.data?.output_columns
          // reset zeroConfig after output columns generation
          if (data.operation_name === DUPLICATE_ROW) {
            state.zeroConfigNodeIndex = -1
          }
          state.loader = false
        }
      )
      builder.addCase(getOutputColumns.rejected, (state, { meta }) => {
        // reset zeroConfig after output columns generation
        if (meta?.arg?.data?.data?.operation_name === DUPLICATE_ROW) {
          state.zeroConfigNodeIndex = -1
        }
        state.loader = false
      })
      builder.addCase(createAnalyticsPipeline.pending, (state) => {
        state.loader = true
        state.pipelineNameError = false
      })
      builder.addCase(createAnalyticsPipeline.fulfilled, (state) => {
        state.loader = false
      })
      builder.addCase(
        createAnalyticsPipeline.rejected,
        (state, { payload }: { payload: any }) => {
          if (payload.message.indexOf('Unique constraint') > -1) {
            state.pipelineNameError = true
          }
          state.loader = false
        }
      )
      builder.addCase(getAnalyticsPipelineSummary.pending, (state) => {
        state.loader = true
      })
      builder.addCase(
        getAnalyticsPipelineSummary.fulfilled,
        (state, { payload }) => {
          const { nodes, edges } = payload.pipeline_map
          state.nodes = nodes.map((nd: Node) => {
            if (nd.data.type === EXTRACT) {
              nd.data['ingestion'] = state.dataIngestions.find(
                (ingestion) => ingestion.id === nd.data.ingestion
              )
            }
            return nd
          })
          state.edges = edges
          state.outputs = payload.outputs
          state.loader = false
        }
      )
      builder.addCase(getAnalyticsPipelineSummary.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(saveAnalyticsPipelineMap.pending, (state) => {
        state.loader = true
      })
      builder.addCase(
        saveAnalyticsPipelineMap.fulfilled,
        (state, { payload, meta }) => {
          if (payload.status) {
            const record = {
              id: cuid(),
              filename: meta.arg.data.name + '.json',
              filepath: payload.file_path,
              created: new Date().toISOString(),
            }
            state.savedPipelines.unshift(record)
          }
          state.loader = false
        }
      )
      builder.addCase(saveAnalyticsPipelineMap.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(triggerAnalyticsPipeline.pending, (state) => {
        state.loader = true
      })
      builder.addCase(triggerAnalyticsPipeline.fulfilled, (state) => {
        state.loader = false
      })
      builder.addCase(triggerAnalyticsPipeline.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(getDataIngestionsDNA.pending, (state) => {
        state.loader = true
      })
      builder.addCase(getDataIngestionsDNA.fulfilled, (state, { payload }) => {
        state.dataIngestions = payload.data.dataIngestions.data
        state.loader = false
      })
      builder.addCase(getDataIngestionsDNA.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(deleteAnalyticsPipeline.pending, (state) => {
        state.loader = true
      })
      builder.addCase(deleteAnalyticsPipeline.fulfilled, (state) => {
        state.loader = false
      })
      builder.addCase(deleteAnalyticsPipeline.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(getSavedPipelinesPath.pending, (state) => {
        state.loader = true
      })
      builder.addCase(getSavedPipelinesPath.fulfilled, (state, { payload }) => {
        state.loader = false
        state.savedPipelines = payload.map((item) => ({ id: cuid(), ...item }))
      })
      builder.addCase(getSavedPipelinesPath.rejected, (state) => {
        state.loader = false
      })
      builder.addCase(getSavedPipelineMap.pending, (state) => {
        state.loader = true
        state.loadingMap = true
        state.nodes = []
        state.edges = []
      })
      builder.addCase(getSavedPipelineMap.fulfilled, (state, { payload }) => {
        state.loader = false
        state.edges = payload.edges
        state.nodes = payload.nodes.map((node: Node) => {
          const { ingestion, cdm_file, aggregation_type, operation_name } =
            node.data

          const newNode = { ...node }
          newNode.data.label = operation_name
          if (ingestion) {
            newNode.data.ingestion = state.dataIngestions.find(
              (item) => item.id === ingestion
            )
          }
          if (cdm_file) {
            newNode.data.cdm_file = state.dataIngestions
              .find((item) => item.id === ingestion)
              ?.cdmEntities.find(
                (entity) => entity.cdmEntity.systemName === cdm_file
              )?.cdmEntity
          }
          if (aggregation_type && typeof aggregation_type == 'string') {
            newNode.data.aggregation_type = {
              title: aggregation_type.toUpperCase(),
              value: aggregation_type,
            }
          }
          newNode.data.bkd_value = node.data.bkd_column
          return newNode
        })
      })
      builder.addCase(getSavedPipelineMap.rejected, (state) => {
        state.loader = false
        state.loadingMap = false
      })
      builder.addCase(getAnalyticsPipelineNodeOutput.pending, (state) => {
        state.loader = true
      })
      builder.addCase(getAnalyticsPipelineNodeOutput.fulfilled, (state) => {
        state.loader = false
      })
      builder.addCase(getAnalyticsPipelineNodeOutput.rejected, (state) => {
        state.loader = false
      })
    },
  })
}

export const {
  handleBasicFormDetails,
  resetDigitalAuditAnalytics,
  addNode,
  removeNode,
  removeEdge,
  modifyEdge,
  addEdges,
  handleColumnChange,
  updateActiveNodeIndex,
  toggleConfigPanel,
  handleNodeEdges,
  resetNode,
  modifyNodePosition,
  toggleLoadMap,
} = digitalAuditStateSlice.actions

export const digitalAuditReducer = digitalAuditStateSlice.reducer
