import React, { useState } from 'react'
import { DaaLayout } from '@engine-b/shared/components'
import {
  Backdrop,
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { ReactComponent as BackButton } from './assets/BackButton.svg'
import MainFlow from './MainFlow'
import ConfigurationPanel from './ConfigurationPanel'
import {
  Edge,
  Node,
  Option,
  createAnalyticsPipeline,
  deleteAnalyticsPipeline,
  getSavedPipelineMap,
  saveAnalyticsPipelineMap,
  toggleLoadMap,
  triggerAnalyticsPipeline,
  useIEDispatch,
  useIESelector,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'
import { useMsal } from '@azure/msal-react'
import {
  asyncTokenLookup,
  protectedResources,
} from '@engine-b/integration-engine/features/auth'
import { useHistory } from 'react-router-dom'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'
import { useIntl } from 'react-intl'
import {
  DUPLICATE_ROW,
  EXTRACT,
  LOAD,
  MULTIPLE_CALCULATION,
  NET,
  TRANSFORM,
} from './constants'
import DataAndAnalyticsDetails from './DataAndAnalyticsDetails'
import SavePipelineDialog from './SavePipelineDialog'
import SavedPipelinesList from './SavedPipelinesList'
import { DNA_TITLE } from 'apps/integration-engine/src/components/constants'
import DialogComponent from 'apps/integration-engine/src/components/SideBarNav/UserLeaveDialog'

const useStyles = makeStyles((theme) =>
  createStyles({
    CreateAnalyticsPipelineContainer: {
      display: 'flex',
      flexDirection: 'column',
      background: '#F5F7F8 0% 0% no-repeat padding-box',
      maxWidth: 'calc(100vw - 280px)',
      padding: '34px 40px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
      overflow: 'auto',
    },
    Title: {
      fontWeight: 700,
      fontSize: '34px',
      lineHeight: '54px',
      color: '#222222',
      marginBottom: '17px',
    },
    BackButtonIcon: {
      width: 'max-content',
      minWidth: 'unset',
      cursor: 'pointer',
    },
    ButtonText: {
      marginLeft: '8px',
      font: `normal normal 700 14px/17px ${theme.typography.fontFamily}`,
      letterSpacing: 0,
      textAlign: 'left',
      color: '#00B2A9',
    },
    ButtonText1: {
      marginLeft: '23px',
      color: '#8C8D8D',
      font: `normal normal 400 14px/17px ${theme.typography.fontFamily}`,
      '& > span': {
        color: '#222',
      },
    },
    FlowContainer: {
      height: '100%',
      width: '100%',
    },
  })
)

export const CreateAnalyticsPipeline = () => {
  const classes = useStyles()
  const dispatch = useIEDispatch()
  const client = useApolloClient()
  const { instance, inProgress, accounts } = useMsal()
  const history = useHistory()
  const { formatMessage, locale } = useIntl()

  const [savePipelineMapDialog, setSavePipelineMapDialog] = useState(false)
  const [savedPipelinesDialog, setSavedPipelinesDialog] = useState(false)
  const [unsavedChangesDialog, setUnsavedChangesDialog] = useState(false)

  const {
    configPanel,
    name,
    clientDetails,
    engagementDetails,
    loader,
    nodes,
    edges,
    dataIngestions,
    loadingMap,
  } = useIESelector((state) => state.digitalAuditAnalytics)

  /**
   * Check whether drawn node are filled and configured or not
   * @returns Boolean
   */
  const isAllNodesAreFilled = () => {
    return nodes.every((node: Node) => {
      if (node.data.type === TRANSFORM) {
        if (
          (!node.data.column_name &&
            ![MULTIPLE_CALCULATION, DUPLICATE_ROW].includes(
              node.data.operation_name
            )) ||
          !node.data.input_columns ||
          !node.data.output_columns
        ) {
          return false
        }
        if (node.data.operation_name === NET && !node.data.dci_column) {
          return false
        }
        if (
          node.data.operation_name === MULTIPLE_CALCULATION &&
          !node.data.data
        ) {
          return false
        }
      } else if (
        node.data.type === EXTRACT &&
        (!node.data.ingestion || !node.data.cdm_file)
      ) {
        return false
      } else if (
        node.data.type === LOAD &&
        (!node.data.file_name?.trim() || !node.data.input_columns)
      ) {
        return false
      }
      return true
    })
  }

  /**
   * Disable Create pipeline button until below conditions meet
   * 1) Check whether Pipeline name, client and engagement are selected
   * 2) Check whether all inputs are selected and filledCheck whether all inputs are selected and filled
   * 3) Check whether all nodes are connected.
   * @returns boolean
   */
  const disablePipelineButton = () => {
    // Check whether Pipeline name, client and engagement are selected
    const isConfigValid =
      !!name.trim() && !!clientDetails && !!engagementDetails

    // Check whether all inputs are selected and filled
    const isAllNodesFilled = isAllNodesAreFilled()

    // Check whether all nodes are connected.
    const allNodesConnected = nodes.every((node: Node) => {
      if (
        node.data.type === EXTRACT &&
        edges.filter((x: Edge) => x.source === node.id).length === 0
      ) {
        return false
      }

      if (
        node.data.type === 'transform' &&
        (edges.filter((x: Edge) => x.source === node.id).length === 0 ||
          edges.filter((y: Edge) => y.target === node.id).length === 0)
      ) {
        return false
      }
      if (
        node.data.type === LOAD &&
        edges.filter((y: Edge) => y.target === node.id).length === 0
      ) {
        return false
      }
      return true
    })

    // Output file name should not be the same
    const isOutputFileNamesAreSame = nodes
      .filter((node: Node) => node.data.type === LOAD)
      .every((node: Node) => {
        return !node.data.loadFileNameError
      })

    // If every input is selected and filled
    return (
      isConfigValid &&
      isAllNodesFilled &&
      allNodesConnected &&
      edges.length >= 2 &&
      isOutputFileNamesAreSame
    )
  }

  /**
   * Disable Save and Saved Pipeline button until required inputs get filled
   * @param type string this could be 'fetch' or 'save'
   * @returns boolean
   */
  const disableSaveButton = (type: string) => {
    // Check whether Pipeline name, client and engagement are selected
    const isConfigValid =
      !!name.trim() && !!clientDetails && !!engagementDetails

    // Output file name should not be the same
    const isOutputFileNamesAreSame = nodes
      .filter((node: Node) => node.data.type === LOAD)
      .every((node: Node) => {
        return !node.data.loadFileNameError
      })

    return !(
      isConfigValid &&
      ((isAllNodesAreFilled() &&
        isOutputFileNamesAreSame &&
        nodes.length > 0) ||
        type === 'fetch')
    )
  }

  /**
   * Prepare payload as per requireents and call save analytics cm-api endpoint
   * PipelineName and is_save is optional params which changes on save option
   * @param pipelineId
   * @param pipelineName
   * @param is_saved
   */
  const triggerDataAndAnalyticsPipeline = async (
    pipelineId: string,
    pipelineName: string,
    is_saved = false
  ) => {
    const { name: clientName, auditFirm } = clientDetails
    const payload = {
      name: is_saved ? pipelineName : name.trim(),
      audit_firm: auditFirm?.systemName,
      client: clientName,
      engagement: engagementDetails.name,
      is_saved,
      ...(pipelineId && { pipeline_id: pipelineId }),
      pipeline_map: {
        nodes: [...nodes],
        edges: [...edges],
      },
    }
    payload.pipeline_map.nodes = payload.pipeline_map.nodes.map((node) => {
      node = {
        ...node,
        data: {
          ...node.data,
          ...(node.data.ingestion && {
            ingestion: node.data.ingestion.id,
          }),
          ...(node.data.cdm_file && {
            cdm_file: node.data.cdm_file.systemName,
          }),
          /** Add below properties for node with type load
           * 1) file_path
           * 2) file_name
           */
          ...(node.data.type === LOAD && {
            file_path: `https://${process.env['NX_ADF_STORAGE_ACCOUNT_NAME']}.blob.core.windows.net/${auditFirm?.systemName}/analytics/${clientName}/${engagementDetails.name}/pipelines/${pipelineId}/output/${node.data.file_name}.csv`,
          }),
          ...(node.data.type === LOAD && {
            file_name: node.data.file_name.trim(),
          }),
        },
      }
      return node
    })

    const { token } = await asyncTokenLookup({
      instance,
      inProgress,
      accounts,
      tokenRequest: protectedResources.dataIngestionApi,
    })
    dispatch(saveAnalyticsPipelineMap({ data: payload, token }))
      .unwrap()
      .then((response) => {
        // Trigger analytics pipeline
        if (!is_saved) {
          dispatch(
            triggerAnalyticsPipeline({
              client,
              id: pipelineId,
              path: response.file_path,
            })
          )
            .unwrap()
            .then(() => {
              history.push(
                localizeRouteKey(
                  formatMessage,
                  locale,
                  AppRoute.DataAndAnalitics
                )
              )
            })
        }
      })
      .catch(() => {
        // Delete the database entry if server failed to save pipeline map file
        if (pipelineId)
          dispatch(deleteAnalyticsPipeline({ client, pipelineId }))
      })
  }

  /**
   * Create database entry
   * 1) Trigger pipeline run on pipeline creation success
   * 2) Else delete the database entry on pipeline creation failure
   */
  const createDataAndAnalyticsPipeline = () => {
    dispatch(
      createAnalyticsPipeline({
        client,
        name: name.trim(),
        engagementId: engagementDetails.id,
      })
    )
      .unwrap()
      .then((response) => {
        if (dataIngestions.length > 0) {
          triggerDataAndAnalyticsPipeline(
            response.data.createAnalyticsPipeline.id,
            ''
          )
        }
      })
  }

  const loadSavedPipeline = async (flag: boolean, file_name: string) => {
    setSavedPipelinesDialog(false)
    if (flag) {
      const { token } = await asyncTokenLookup({
        instance,
        inProgress,
        accounts,
        tokenRequest: protectedResources.dataIngestionApi,
      })
      const data = {
        name: file_name,
        audit_firm: clientDetails.auditFirm?.systemName,
        client: clientDetails.name,
        engagement: engagementDetails.name,
        is_saved: true,
      }

      dispatch(getSavedPipelineMap({ data, token }))
        .unwrap()
        .then(() => {
          dispatch(toggleLoadMap({}))
        })
    }
  }

  const manageBackButton = () => {
    if (name.length > 0 || clientDetails !== null || nodes.length > 0) {
      setUnsavedChangesDialog(true)
    } else {
      history.push(
        localizeRouteKey(formatMessage, locale, AppRoute.DataAndAnalitics)
      )
    }
  }

  const onUnsavedPipelineDialogClose = (value: boolean) => {
    if (value) {
      history.push(
        localizeRouteKey(formatMessage, locale, AppRoute.DataAndAnalitics)
      )
    } else {
      setUnsavedChangesDialog(false)
    }
  }
  return (
    <section className={classes.CreateAnalyticsPipelineContainer}>
      <Typography className={classes.Title} color="primary">
        Data & Analytics
      </Typography>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ margin: '1rem 0' }}
      >
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            className={classes.BackButtonIcon}
            onClick={() => manageBackButton()}
          >
            <BackButton />
            <Typography className={classes.ButtonText}>Back</Typography>
          </Button>
          <Typography className={classes.ButtonText1}>
            Data & Analytics / <span>Create Pipeline</span>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            style={{ minWidth: 'unset', height: '48px' }}
            color="primary"
            variant="contained"
            disabled={disableSaveButton('fetch')}
            onClick={() => setSavedPipelinesDialog(true)}
          >
            Saved Pipelines
          </Button>
          <Button
            style={{ minWidth: 'unset', height: '48px', margin: '0 1rem' }}
            color="primary"
            variant="contained"
            disabled={disableSaveButton('save')}
            onClick={() => setSavePipelineMapDialog(true)}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ height: '48px' }}
            disabled={!disablePipelineButton()}
            onClick={createDataAndAnalyticsPipeline}
          >
            Create Pipeline
          </Button>
        </Grid>
      </Grid>
      <DaaLayout configPanel={configPanel}>
        <ConfigurationPanel />
        <DataAndAnalyticsDetails />
        <MainFlow loadMap={loadingMap} />
      </DaaLayout>
      <Grid container style={{ marginTop: '1rem' }}>
        <Grid item style={{ width: '100%', textAlign: 'right' }}></Grid>
      </Grid>
      <SavePipelineDialog
        open={savePipelineMapDialog}
        onClose={(flag, pipelineName) => {
          if (flag) triggerDataAndAnalyticsPipeline('', pipelineName, true)
          setSavePipelineMapDialog(false)
        }}
      />
      <DialogComponent
        open={unsavedChangesDialog}
        onClose={onUnsavedPipelineDialogClose}
        cancelText="No"
        confirmText="Yes"
        title={DNA_TITLE}
        subtitle={''}
      />
      <SavedPipelinesList
        open={savedPipelinesDialog}
        onClose={loadSavedPipeline}
      />
      <Backdrop style={{ zIndex: 1100 }} open={loader}>
        <Box width="20%" mr={1}>
          <LinearProgress color="secondary" />
        </Box>
      </Backdrop>
    </section>
  )
}

export default CreateAnalyticsPipeline
