import { useApolloClient } from '@apollo/client'
import {
  getDataIngestions,
  getErps,
  useIEDispatch,
  useIESelector,
  STATUS_TITLES,
  STATUS_COLORS,
  LOADING_STATE,
} from '@engine-b/integration-engine/data/state/redux'
import { Grid, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { enUS, enGB } from 'date-fns/locale'
import { useIntl } from 'react-intl'
import Actions from './Actions'
import StatusViewFilters from './StatusViewFilters'
import { EBTable } from '@engine-b/shared/components'
import {trackPageView} from "../../services/AppInsights"

const useStyles = makeStyles((theme) => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      '& td': {
        font: 'normal normal normal 16px/18px Arial',
      },
    },
    sectionStyles: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 80px)',
      maxWidth: 'calc(100vw - 280px)',
      padding: '30px',
      background: '#F0FBFA 0% 0% no-repeat padding-box',
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px !important',
      },
    },
    header: {
      font: `normal normal bold 30px/37px ${theme.typography.fontFamily}`,
      letterSpacing: '0.3px',
      color: '#22353F',
      opacity: 1,
    },
    statusStyles: {
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.16px',
      width: '79px',
      whiteSpace: 'nowrap',
      display: 'inline',
    },
  })
})

export function StatusView() {
  const { container, header, statusStyles, sectionStyles } = useStyles()
  const { locale } = useIntl()

  const { runs, loadingState, total, triggerLoadingState } = useIESelector(
    (state) => state.runs
  )
  const erps = useIESelector((state) => state.erps)

  const dataIngestionRows = useMemo(() => {
    return runs.map((run) => {
      return {
        id: run.id,
        ingestionName: run.name,
        clientName: run.auditedEntityName,
        engagementName: run?.engagement?.name || '',
        erpSystemName: erps?.[run.erpId]?.name || '',
        initiatedAt: run?.initiatedAt,
        ingestionDateTime:
          run.initiatedAt === null
            ? 'Not started'
            : format(parseISO(run.initiatedAt), 'P, h:mm:ss a', {
                locale: locale === 'en-US' ? enUS : enGB,
              }),
        uiState: run.uiState,
        container: run.container,
        dataStartDate: run.dataStartDate,
        cdmEntities: run.cdmEntities,
        auditedEntity: run?.auditedEntity,
        engagement: run?.engagement,
        completeAt: run?.completeAt,
        uploadedFiles: run?.uploadedFiles,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runs, erps])

  const [filterState, setFilterState] = useState({
    clientName: '',
    engagementName: '',
    ingestionName: '',
    ingestionDate: '',
    status: 'all',
  })

  const [page, setPage] = useState(1)
  const [recordsPerPage, setRecordsPerPage] = useState(5)

  const dispatch = useIEDispatch()
  const client = useApolloClient()

  useEffect(() => {
    dispatch(getErps(client))
    trackPageView({name: 'Document Status'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFilterState({
      ...filterState,
      engagementName: '',
    })
  }, [filterState.clientName])

  const applyFilter = ({ event, value, filterName }) => {
    setFilterState((state) => ({ ...state, [filterName]: value }))
  }

  const tableHeadersData = useMemo(() => {
    return [
      {
        title: 'Client Name',
        value: 'clientName',
        width: '100px',
      },
      {
        title: 'Engagement Name',
        value: 'engagementName',
        width: '100px',
      },
      {
        title: 'Ingestion Name',
        value: 'ingestionName',
        width: '100px',
      },
      {
        title: 'ERP System',
        value: 'erpSystemName',
        width: '100px',
      },
      {
        title: 'Ingestion Date-Time',
        value: 'ingestionDateTime',
        width: '220px',
      },
      {
        title: 'Status',
        value: 'uiState',
        width: '100px',
        renderer: function (params) {
          const {
            row: { uiState },
          } = params
          return (
            <Typography
              className={statusStyles}
              style={{ color: STATUS_COLORS[uiState] }}
            >
              {STATUS_TITLES[uiState] || 'N/A'}
            </Typography>
          )
        },
      },
      {
        title: '',
        value: 'uiState',
        renderer: function (params) {
          return <Actions rowData={params} />
        },
        width: '220px',
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const offset = (page: number, recordsPerPage: number) => {
    setPage(page)
    setRecordsPerPage(recordsPerPage)
  }

  return (
    <section className={sectionStyles}>
      <Grid container className={container} spacing={4}>
        <Grid item>
          <Typography className={header} color="primary">
            Status of Data Ingestions
          </Typography>
        </Grid>
        <Grid container item spacing={2}>
          <StatusViewFilters
            filterState={filterState}
            applyFilter={applyFilter}
            page={page}
            recordsPerPage={recordsPerPage}
          />
        </Grid>
        <Grid container item direction="column" style={{ flexGrow: 1 }}>
          <EBTable
            loading={loadingState === LOADING_STATE.LOADING}
            headers={tableHeadersData}
            rows={dataIngestionRows}
            recordsPerPage={recordsPerPage}
            footerContent={null}
            styles={{
              tableBoxStyles: {
                borderRadius: '8px',
              },
            }}
            emptyLabel={'No Data To Display'}
            total={total}
            filterState={
              triggerLoadingState === LOADING_STATE.DONE && filterState
            }
            offset={offset}
          />
        </Grid>
      </Grid>
    </section>
  )
}

export default StatusView
