import React, {
  useMemo,
  useState,
  useEffect,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Typography,
  Grid,
  StyleRules,
  Theme,
  Box,
  LinearProgress,
} from '@material-ui/core'
import { Select } from '@engine-b/shared/components'
import { Pagination } from '@material-ui/lab'
import {
  getDataIngestions,
  useIEDispatch,
} from '@engine-b/integration-engine/data/state/redux'
import { useApolloClient } from '@apollo/client'

const useStyles = makeStyles<Theme, StyleRules>((theme) => ({
  tableHeaders: {
    letterSpacing: '0.16px',
    color: '#44697D',
    position: 'sticky',
    zIndex: 1,
    top: '0px',
    backgroundColor: 'white',
    '& .MuiTableCell-head': {
      background: '#fff',
      font: 'normal normal bold 16px/18px Arial',
      whiteSpace: 'nowrap',
    },
  },
  tableFooter: {
    backgroundColor: 'white',
    padding: '20px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .footer-pagination-section': {},
    '& .footer-content-section': {
      display: 'flex',
    },
  },
  paginationRoot: {
    margin: 0,
    borderRadius: 0,
    '& .MuiPagination-ul': {
      borderRadius: '8px',
      border: '1px solid #D7DAE2',
      '& li:not(:first-child)': {
        borderLeft: '1px solid #D7DAE2',
      },
    },
    tableFooter: {
      padding: '20px 15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& .footer-pagination-section': {},
      '& .footer-content-section': {},
    },
    paginationRoot: {
      margin: 0,
      borderRadius: 0,
      '& .MuiPagination-ul': {
        borderRadius: '8px',
        border: '1px solid #D7DAE2',
        '& li:not(:first-child)': {
          borderLeft: '1px solid #D7DAE2',
        },
        '& .MuiPaginationItem-icon': {
          color: '#00B2A9',
        },
        '& .MuiPaginationItem-page': {
          margin: '0px',
        },
      },
      '& .MuiPaginationItem-textPrimary.Mui-selected': {
        background: '#00B2A9 0% 0% no-repeat padding-box',
      },
    },
    '& .MuiPaginationItem-textPrimary.Mui-selected': {
      background: '#00B2A9 0% 0% no-repeat padding-box',
    },
  },
  tableContainer: ({ tableContainerStyles }) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      ...tableContainerStyles,
    }
  },
  tableBoxStyles: ({ tableBoxStyles, theme }) => {
    return {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      borderWidth: '1 1px 1px 1px',
      borderColor: '#D5DCE1',
      borderStyle: 'solid',
      width: '100%',
      flexBasis: '600px',
      overflowY: 'auto',
      ...tableBoxStyles,
    }
  },
  noDataMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    font: 'normal normal bold 30px/50px Arial',
    letterSpacing: '0.36px',
    color: '#89A0AC',
    opacity: '0.4',
  },
  recordsPerPageStyles: {
    display: 'flex',
    gap: 5,
    justifyContent: 'justify-content',
    alignItems: 'center',
    font: 'normal normal normal 0.875rem Arial',
    fontFamily: theme.typography.fontFamily,
    color: '#395c74',
  },
}))

export interface EBTableHeaders<RowType> {
  title: string
  value: string
  width?: string
  colspan?: number
  sticky?: boolean
  filterRenderer?: () => ReactNode
  renderer?: (params: {
    rowIndex: number
    fieldName: string
    row: RowType
  }) => ReactElement
}
export interface EBTableProps<RowType> {
  rows: Array<RowType>
  filterEnabled?: boolean
  headers: EBTableHeaders<RowType>[]
  footerContent?: ReactElement | null
  recordsPerPage: number
  setRecordsPerPage?: any
  emptyLabel?: string | number | ReactNode
  styles?: StyleRules
  showRecordsPerPage?: boolean
  loading?: boolean
  changeRecords?: any
  total?: number
  filterState?: any
  offset?: any
}

const defaultRenderer = (params: any) => {
  const { row, fieldName = '', rowIndex } = params
  if (row[fieldName]) {
    return row[fieldName]
  }
  return ''
}
export const EBTable = <T,>({
  rows,
  filterEnabled,
  headers,
  footerContent = null,
  recordsPerPage: recordsPerPageDefault = 5,
  emptyLabel = 'No Data To Display',
  styles = {},
  showRecordsPerPage = true,
  loading = false,
  changeRecords = null,
  total = 0,
  filterState,
  offset,
}: EBTableProps<T>) => {
  const {
    tableHeaders,
    tableFooter,
    paginationRoot,
    tableContainer,
    noDataMessage,
    recordsPerPageStyles,
    tableBoxStyles,
  } = useStyles(styles)

  const client = useApolloClient()
  const dispatch = useIEDispatch()

  const [page, setPage] = useState(1)
  const [currentpage, setCurrentPage] = useState(1)
  const [refLoaded, setRefLoaded] = useState(false)
  const [columnOffsets, setColumnOffsets] = useState<string[]>([])
  const [recordsPerPage, setRecordsPerPage] = useState(
    recordsPerPageDefault || 5
  )

  const paginatedRecords = useMemo(() => {
    const result = rows.slice(
      recordsPerPage * (page - 1),
      recordsPerPage * page
    )
    return result
  }, [rows, page, recordsPerPage])

  const pageOption = useMemo(() => {
    if (location.pathname.split('/').pop() === 'status') {
      const result = [5, 10, 25, 50, 100, 500]
        .filter((item) => item < total)
        .concat(total > 0 && total <= 500 ? [total] : [])
        .map((item) => ({ label: item, value: item }))
      return result
    } else {
      const result = [5, 10, 25, 50, 100, 500]
        .filter((item) => item < rows.length)
        .concat(rows.length > 0 && rows.length <= 500 ? [rows.length] : [])
        .map((item) => ({ label: item, value: item }))
      return result
    }
  }, [rows.length, total])

  useEffect(() => {
    if (location.pathname.split('/').pop() !== 'status') {
      setPage(1)
    }
  }, [rows.length, recordsPerPage])

  useEffect(() => {
    if (
      pageOption?.length &&
      !Object.values(pageOption)
        .map((item) => item.value)
        .includes(recordsPerPage)
    ) {
      setRecordsPerPage(pageOption[0].value)
    }
  }, [pageOption])

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'status') {
      client.cache.reset()
      // setPage(1)
      dispatch(
        getDataIngestions({
          client,
          page: 1,
          pageSize: Math.max(recordsPerPage, 5),
          auditedEntityId: filterState.clientName,
          engagementId: filterState.engagementName,
          status: filterState.status,
          initiatedAt: filterState.ingestionDate,
          name: filterState.ingestionName,
        })
      )
    }
  }, [filterState])

  useEffect(() => {
    if (location.pathname.split('/').pop() === 'status') {
      offset(page, recordsPerPage)
    }
  }, [page, recordsPerPage])

  const columnConfig = useMemo(() => {
    return headers.reduce((prev, { sticky, renderer, value }, i) => {
      return {
        ...prev,
        [`${i}_${value}`]: {
          renderer: renderer ? renderer : defaultRenderer,
          sticky,
          headerIndex: i,
        },
      }
    }, {})
  }, [headers])

  const handlePaginationChange = (nextPage: number) => {
    if (location.pathname.split('/').pop() === 'status') {
      client.cache.reset()
      setCurrentPage(nextPage)
      dispatch(
        getDataIngestions({
          client,
          page: nextPage,
          pageSize: recordsPerPage,
          auditedEntityId: filterState.clientName,
          engagementId: filterState.engagementName,
          status: filterState.status,
          initiatedAt: filterState.ingestionDate,
          name: filterState.ingestionName,
        })
      )
    } else if (nextPage !== page) {
      setPage(nextPage)
    }
  }

  const changeRecordsPerPage = (value: number) => {
    if (location.pathname.split('/').pop() === 'status') {
      setCurrentPage(1)
      dispatch(
        getDataIngestions({
          client,
          page,
          pageSize: value,
          auditedEntityId: filterState.clientName,
          engagementId: filterState.engagementName,
          status: filterState.status,
          initiatedAt: filterState.ingestionDate,
          name: filterState.ingestionName,
        })
      )
    }
    setRecordsPerPage(value || 5)
    changeRecords(value)
  }

  const getStickyStyles = (headerIndex: number) => {
    if (refLoaded) {
      const offset = columnOffsets[headerIndex] || 0
      const commonStyles = {
        position: 'sticky',
        zIndex: 100,
        backgroundColor: 'white',
        left: `${offset}px`,
      } as React.CSSProperties
      return { ...commonStyles, left: `${offset}px` }
    }
    return {}
  }

  const callbackRef = useCallback((node) => {
    if (node !== null) {
      if (node.children) {
        const headerOffsets = Array.from(node.children).map((th: any) => {
          return th.offsetLeft
        })
        setColumnOffsets(headerOffsets)
      }
      setRefLoaded(true)
    }
  }, [])
  return (
    <Box className={tableBoxStyles}>
      <TableContainer
        className={tableContainer}
        component={Paper}
        elevation={0}
      >
        {
          <Table
            style={{
              borderCollapse: 'unset',
              ...(!rows.length && {
                flex: 0,
              }),
            }}
          >
            <TableHead className={tableHeaders}>
              <TableRow ref={callbackRef}>
                {headers.map(({ title, width, sticky }, headerIndex) => {
                  return (
                    <TableCell
                      style={{
                        marginTop: '30px',
                        minWidth: width,
                        ...(sticky && getStickyStyles(headerIndex)),
                      }}
                      key={title}
                    >
                      {title}
                    </TableCell>
                  )
                })}
              </TableRow>
              {filterEnabled && (
                <TableRow style={{ backgroundColor: '#F8FAFB' }}>
                  {headers.map(
                    ({ title, width, filterRenderer, sticky }, headerIndex) => {
                      return (
                        <TableCell
                          style={{
                            minWidth: width,
                            ...(sticky && getStickyStyles(headerIndex)),
                            backgroundColor: '#F8FAFB',
                          }}
                          key={title}
                        >
                          {filterRenderer ? filterRenderer() : null}
                        </TableCell>
                      )
                    }
                  )}
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell
                    colSpan={headers.length + 1}
                    style={{ padding: '0px' }}
                  >
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            {rows.length ? (
              <TableBody>
                {(location.pathname.split('/').pop() === 'status'
                  ? rows
                  : paginatedRecords
                ).map((row: any, i) => {
                  const rowIndex = recordsPerPage * (page - 1) + i
                  return (
                    <TableRow key={i}>
                      {headers.map(({ value, colspan }, index) => {
                        const { renderer, headerIndex, sticky } =
                          columnConfig[`${index}_${value}`] || {}
                        return (
                          <TableCell
                            key={`${value}_${i}_${headerIndex}`}
                            style={{
                              ...(sticky && getStickyStyles(headerIndex)),
                            }}
                            colSpan={colspan}
                          >
                            {renderer({
                              row: rows[rowIndex],
                              fieldName: value,
                              rowIndex,
                            })}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={headers.length + 1}>
                    <Typography className={noDataMessage}>
                      {!loading && emptyLabel}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        }
      </TableContainer>
      <div className={tableFooter}>
        <div className="footer-content-section">
          {showRecordsPerPage && !loading && (
            <Grid className={recordsPerPageStyles}>
              <Grid item>
                <Typography>Show</Typography>
              </Grid>
              <Grid>
                <Select
                  selectProps={{
                    inputProps: {
                      'data-testid': 'records-per-page',
                    },
                  }}
                  value={pageOption?.length ? recordsPerPage : 0}
                  items={
                    pageOption?.length ? pageOption : [{ label: 0, value: 0 }]
                  }
                  onChangeHandler={(value) => changeRecordsPerPage(value || 5)}
                />
              </Grid>
              <Grid style={{ display: 'flex' }}>
                {location.pathname.split('/').pop() !== 'status' ? (
                  <>
                    <Typography style={{ marginRight: '2px' }}>of</Typography>
                    <Typography data-testid="record-number">
                      {rows?.length || 0} Records
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography style={{ marginLeft: '2px' }}>
                      Per Page
                    </Typography>
                    <Typography style={{ marginLeft: '2px' }}>
                      | Showing{' '}
                      {currentpage * recordsPerPage - (recordsPerPage - 1)} to{' '}
                      {rows?.length < recordsPerPage
                        ? currentpage * recordsPerPage -
                          (recordsPerPage - 1) +
                          (rows?.length - 1)
                        : currentpage * recordsPerPage}{' '}
                      records
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {footerContent}
        </div>
        <div className="footer-pagination-section">
          <Pagination
            classes={{ root: paginationRoot }}
            onChange={(e, page) => handlePaginationChange(page)}
            page={
              location.pathname.split('/').pop() === 'status'
                ? currentpage
                : page
            }
            count={
              Math.ceil(
                location.pathname.split('/').pop() === 'status'
                  ? total / recordsPerPage
                  : rows.length / recordsPerPage
              ) || 1
            }
            variant="text"
            shape="rounded"
            color="primary"
          />
        </div>
      </div>
    </Box>
  )
}

export default EBTable
