import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { DataLakeServiceClient } from '@azure/storage-file-datalake'
import {
  RunStatus,
  Run,
  useIEDispatch,
  reTriggerPipeline,
} from '@engine-b/integration-engine/data/state/redux'
import { Button, Grid, Tooltip } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { saveAs } from 'file-saver'
import { AzureClientContext } from '@engine-b/integration-engine/data/azure-data-factory'
import { StatusCodes } from 'http-status-codes'
import { ReactComponent as IconDownload } from '../../assets/IconDownload.svg'
import { ReactComponent as IconScorecard } from '../../assets/IconScorecard.svg'
import { ReactComponent as IconAlertError } from '../../assets/IconAlertError.svg'
import { ReactComponent as IconSummary } from '../../assets/IconSummary.svg'
import RetryIcon from '@material-ui/icons/Refresh'
import { log } from 'console'
import {
  AppRoute,
  localizeRouteKey,
} from '@engine-b/integration-engine/features/i18n'

const useStyles = makeStyles(() => {
  return createStyles({
    actionButtons: {
      marginRight: '10px',
      font: 'normal normal normal 16px/18px Arial',
      letterSpacing: '0.16px',
      minWidth: '50px',
      height: '34px',
      borderRadius: '5px',
    },
  })
})
const DOWNLOAD_DQS_ENDPOINT = `${process.env.NX_CUSTOM_MAPPER_API_URL}/dqs/scorecard`

export function Actions({ rowData }) {
  const { actionButtons } = useStyles()
  const { locale, formatMessage } = useIntl()
  const dispatch = useIEDispatch()
  const client = useApolloClient()
  const history = useHistory()
  const azureClient = useContext(AzureClientContext)
  const [loading, setLoading] = useState({
    output_file: false,
    scorecard: false,
  })
  const {
    id,
    uiState,
    container,
    erpSystemName,
    clientName,
    completeAt,
    ingestionName,
    engagementName,
    cdmEntities,
    uploadedFiles,
  } = rowData?.row || {}
  const downloadOutPutFile = async (
    azureClient: DataLakeServiceClient,
    diContainer: Run['container']
  ) => {
    setLoading((prev) => ({ ...prev, output_file: true }))
    const containerClient = azureClient.getFileSystemClient(
      diContainer.fileSystemId
    )
    const FILE_NAME = 'cdm.zip'
    const fileClient = containerClient.getFileClient(
      `${diContainer.sharePath}/zip/${FILE_NAME}`
    )
    const downloadResponse = await fileClient.read()
    const downloadBlob = await downloadResponse.contentAsBlob
    const blob = new Blob([downloadBlob], { type: 'application/zip' })
    saveAs(
      blob,
      `cdm_for_${clientName}_${engagementName}_${ingestionName}_${erpSystemName}_${id}.zip`
    )
    setLoading((prev) => ({ ...prev, output_file: false }))
  }

  const downloadPdf = async () => {
    setLoading((prev) => ({ ...prev, scorecard: true }))
    const requestBody = {
      entity_name: clientName,
      erp_name: erpSystemName,
      extract_type: cdmEntities[0].cdmEntity.extractType,
      import_date: completeAt,
      container_path: `${container.fileSystemId}/${container.sharePath}`,
      entities: [
        ...cdmEntities.map((cdm, index) => ({
          entity_type: cdm.cdmEntity.systemName,
          entity_name: cdm.cdmEntity.name,
          files_uploaded: uploadedFiles
            .map((fileName) => fileName?.fileNameByUser)
            .filter((_, i) => index === i),
        })),
      ],
    }
    try {
      const response = await axios.post(DOWNLOAD_DQS_ENDPOINT, requestBody, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.status === StatusCodes.OK) {
        const FILE_NAME = `${clientName}_DQS.zip`
        const downloadBlob = response.data
        const blob = new Blob([downloadBlob], {
          type: 'application/octet-stream',
        })
        saveAs(blob, FILE_NAME)
        setLoading((prev) => ({ ...prev, scorecard: false }))
      }
    } catch (error) {
      setLoading((prev) => ({ ...prev, scorecard: false }))
    }
  }

  return (
    <Grid container>
      {uiState === RunStatus.COMPLETED && (
        <Tooltip title="Download Output File">
          <Button
            disabled={loading['output_file']}
            className={actionButtons}
            color="secondary"
            variant="contained"
            onClick={() => downloadOutPutFile(azureClient, container)}
          >
            <IconDownload />
          </Button>
        </Tooltip>
      )}
      {uiState === RunStatus.COMPLETED && (
        <Tooltip title="Download Data Quality Scorecard">
          <Button
            disabled={loading['scorecard']}
            className={actionButtons}
            color="primary"
            variant="contained"
            onClick={() => downloadPdf()}
          >
            <IconScorecard />
          </Button>
        </Tooltip>
      )}
      {uiState === RunStatus.COMPLETED && (
        <Tooltip title="View Summary">
          <Button
            className={actionButtons}
            color="primary"
            variant="contained"
            onClick={() =>
              history.push(
                localizeRouteKey(formatMessage, locale, AppRoute.Status) +
                  '/' +
                  id
              )
            }
          >
            <IconSummary />
          </Button>
        </Tooltip>
      )}
      {(uiState === RunStatus.ERROR ||
        uiState === RunStatus.FAILED ||
        uiState === RunStatus.COMPLETED_WITH_ERRORS) && (
        <>
          <Tooltip title="View Error">
            <Button
              className={actionButtons}
              variant="contained"
              style={{
                backgroundColor: '#DE4D4D',
                color: 'white',
              }}
              onClick={() =>
                history.push(
                  localizeRouteKey(formatMessage, locale, AppRoute.Status) +
                    '/' +
                    id
                )
              }
            >
              <IconAlertError />
            </Button>
          </Tooltip>
          <Tooltip title="Retry">
            <Button
              className={actionButtons}
              variant="contained"
              style={{
                backgroundColor: '#ecc83c',
                color: 'white',
              }}
              onClick={() =>
                dispatch(
                  reTriggerPipeline({
                    client,
                    dataIngestionId: id,
                    isCustomMapped: true,
                  })
                )
              }
            >
              <RetryIcon />
            </Button>
          </Tooltip>
        </>
      )}
    </Grid>
  )
}

export default Actions
