import React from 'react'
import {
  DaaOperationComponent,
  EBAutoComplete,
} from '@engine-b/shared/components'
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import {
  MultipleCalculationData,
  Option,
} from '@engine-b/integration-engine/data/state/redux'
import {
  COUNT,
  DELETE_COLUMN,
  FILTER,
  GROUP_BY,
  NET,
  NON_WORKING_DAYS,
  OUTSIDE_WORKING_HOURS,
  BACKDATING,
  MULTIPLE_CALCULATION,
  STRING,
  DATA_TYPE_SUPPORT,
  DECIMAL,
  DATETIME,
  FILTER_OPERATORS,
} from './constants'
import WeekDayInput from './WeekDayInput'
import MultipleDatePicker from './MulipleDatePicker'
import MultipleCalculationInput from './MultipleCalculationInput'
import { ReactComponent as IconInfo } from './assets/IconInfo.svg'

interface DaaTransformProps {
  label: string
  column_name: Option | Option[]
  group_by?: Option[]
  dci_column?: Option
  operation_formula?: string
  operation_value?: Option
  operation_name: string
  onChange: (
    key: string,
    value:
      | Option
      | string
      | boolean
      | number[]
      | string[]
      | MultipleCalculationData
      | object
  ) => void
  unique: boolean
  options: any
  aggr_options: any
  aggregation_type?: Option
  weekdays?: number[]
  custom_dates?: string[]
  start_time?: string
  end_time?: string
  bkd_column?: Option
  bkd_value?: Option
  data?: MultipleCalculationData
  expressionError?: string
}

const useStyles = makeStyles(() => ({
  customInput: {
    '& .MuiInputBase-root': {
      height: '48px',
      borderRadius: '8px',
    },
  },
  inputLabel: { color: '#222222', marginBottom: '8px', display: 'flex' },
  tagClass: {
    '& span.MuiChip-label.MuiChip-labelSmall': {
      maxWidth: '184px',
    },
  },
}))

const useTooltipStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      fontSize: 13,
    },
  })
)

const DaaTransform = (props: DaaTransformProps) => {
  const {
    label,
    operation_name,
    column_name,
    group_by,
    dci_column,
    operation_formula,
    operation_value,
    onChange,
    options,
    aggr_options,
    unique,
    aggregation_type,
    weekdays,
    custom_dates,
    start_time,
    end_time,
    bkd_column,
    bkd_value,
    data,
    expressionError,
  } = props
  const { customInput, inputLabel, tagClass } = useStyles()
  const classes = useTooltipStyles()

  const onTimeChangeHandler = (key: string, value: string) => {
    if (
      (key === 'start_time' && end_time && value >= end_time) ||
      (key === 'end_time' && start_time && value <= start_time)
    ) {
      onChange(key, '')
    } else {
      onChange(key, value)
    }
  }

  const getTooltipInfo = (operation_name: string) => {
    const info: { [key: string]: string } = {
      [MULTIPLE_CALCULATION]:
        'Select the individual fields you want to use in your calculation from the dropdown box, and select ‘Add Column’. The fields will be shown below with a letter labelling each one of A, B, C, etc. Once all fields are selected the equation can be written out on the line where it states ‘Type equation here....’. The equation will need to be written using the relevant letter corresponding to the field and the required function i.e. A*(B+C).',
      [NON_WORKING_DAYS]:
        'Select the specified day(s) of the week that are considered to be non-working and/or input any custom dates below. Once the custom date has been chosen use the green tick button to select the date to be included in the operation. Continue this process to add multiple custom dates.',
    }

    return info[operation_name]
  }

  const getInputType = () => {
    if (!Array.isArray(column_name)) {
      if (column_name?.data_type === 'datetime') {
        return 'date'
      } else if (column_name?.data_type === 'decimal') {
        return 'number'
      } else {
        return 'string'
      }
    } else {
      return 'string'
    }
  }

  const columnInfo = {
    [FILTER]: `Select the first field on which the operator is to be performed on.`,
    [GROUP_BY]: `Select the column on which to perform the aggregator calculation.`,
  }

  return (
    <DaaOperationComponent
      display_name={label}
      info={getTooltipInfo(operation_name)}
    >
      {/* Below UI renders only when operation/label is multiple_calculation */}
      {operation_name === MULTIPLE_CALCULATION && (
        <Grid item>
          <MultipleCalculationInput
            options={options.filter((option: Option) =>
              [DECIMAL].includes(option?.data_type || '')
            )}
            onChange={(data: MultipleCalculationData) => onChange('data', data)}
            data={data}
            error={expressionError}
          />
        </Grid>
      )}

      {/* Below UI renders only when operation/label is non_working_days */}
      {label === NON_WORKING_DAYS && (
        <>
          <Grid item>
            <InputLabel className={inputLabel}>
              Select day(s) of the week
            </InputLabel>
            <WeekDayInput
              weekdays={weekdays}
              onChange={(values: number[]) => onChange('weekdays', values)}
            />
          </Grid>
          <Grid item>
            <InputLabel className={inputLabel}>Custom Dates</InputLabel>
            <MultipleDatePicker
              custom_dates={custom_dates}
              onChange={(dates: string[]) => onChange('custom_dates', dates)}
            />
          </Grid>
        </>
      )}

      {/* Below UI renders only when operation/label is net */}
      {operation_name === NET && (
        <Grid item>
          <InputLabel className={inputLabel}>
            DCI (Debit Credit Indicator)&nbsp;
            <Tooltip
              title={
                'Select the column from the CDM which is the debit credit indicator field for the selected amount column.'
              }
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconInfo />
            </Tooltip>
          </InputLabel>
          <EBAutoComplete
            options={options.filter((option: Option) =>
              [STRING].includes(option?.data_type || '')
            )}
            getOptionLabel={(option) => option.title || ''}
            forcePopupIcon={true}
            height={48}
            size="small"
            value={dci_column || null}
            onChange={(e, value) => onChange('dci_column', value)}
          />
        </Grid>
      )}

      {/* Below UI renders only when operation/label is backdating */}
      {operation_name === BACKDATING && (
        <>
          <Grid item>
            <InputLabel className={inputLabel}>Backdating Column</InputLabel>
            <EBAutoComplete
              options={[
                { title: 'Custom Input' },
                ...options.filter((option: Option) =>
                  [DATETIME].includes(option?.data_type || '')
                ),
              ]}
              getOptionLabel={(option: Option) => option.title || ''}
              forcePopupIcon={true}
              height={48}
              size="small"
              value={bkd_value || null}
              onChange={(e, value: Option) => {
                onChange('bkd_value', value)
                onChange('bkd_column', value)
              }}
            />
          </Grid>
          {bkd_value?.title === 'Custom Input' && (
            <Grid item>
              <InputLabel className={inputLabel}>Value</InputLabel>
              <TextField
                variant="outlined"
                fullWidth
                type="date"
                placeholder=""
                color="secondary"
                className={customInput}
                value={bkd_column?.value}
                onChange={(e: any) =>
                  onChange('bkd_column', {
                    title: 'Custom Input',
                    value: e.target.value,
                    data_type: 'datetime',
                  })
                }
              />
            </Grid>
          )}
        </>
      )}

      {/* Below UI should not render when operation/label is multiple_calculation */}
      {operation_name !== MULTIPLE_CALCULATION && (
        <Grid item>
          <InputLabel className={inputLabel}>
            Column{[DELETE_COLUMN].includes(operation_name) && '(s)'}&nbsp;
            {(operation_name === GROUP_BY || operation_name === FILTER) && (
              <Tooltip
                title={columnInfo[operation_name]}
                placement="right"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconInfo />
              </Tooltip>
            )}
          </InputLabel>
          {operation_name === DELETE_COLUMN ? (
            <EBAutoComplete
              options={options}
              getOptionLabel={(option) => option.title || ''}
              forcePopupIcon={true}
              multiple={true}
              disableCloseOnSelect={true}
              classes={{ root: tagClass }}
              height={48}
              size="small"
              value={column_name || []}
              onChange={(e, value) => onChange('column_name', value)}
            />
          ) : (
            <EBAutoComplete
              options={options.filter((option: Option) =>
                DATA_TYPE_SUPPORT[operation_name].includes(
                  option?.data_type || ''
                )
              )}
              getOptionLabel={(option) => option.title || ''}
              forcePopupIcon={true}
              height={48}
              size="small"
              value={column_name || null}
              onChange={(e, value) => onChange('column_name', value)}
            />
          )}
        </Grid>
      )}

      {/* Below UI renders only when operation/label is group */}
      {label === GROUP_BY && (
        <>
          <Grid item>
            <InputLabel className={inputLabel}>Aggregator</InputLabel>
            <EBAutoComplete
              options={aggr_options.filter((option: Option) => {
                if (!Array.isArray(column_name)) {
                  return option?.data_types?.includes(
                    column_name?.data_type || ''
                  )
                }
                return true
              })}
              getOptionLabel={(option) => option.title || ''}
              forcePopupIcon={true}
              height={48}
              size="small"
              value={aggregation_type || null}
              onChange={(e, value) => onChange('aggregation_type', value)}
            />
          </Grid>
          <Grid item>
            <InputLabel className={inputLabel}>
              Group By Column(s)&nbsp;
              <Tooltip
                title={
                  'Select the column to group into summary rows/categories.'
                }
                placement="right"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconInfo />
              </Tooltip>
            </InputLabel>
            <div>
              <EBAutoComplete
                options={options}
                getOptionLabel={(option) => option.title || ''}
                forcePopupIcon={true}
                multiple={true}
                disableCloseOnSelect={true}
                classes={{ root: tagClass }}
                height={48}
                size="small"
                value={group_by || []}
                onChange={(_, value) => onChange('group_by', value)}
              />
            </div>
          </Grid>
        </>
      )}

      {/* Below UI renders only when operation/label is count */}
      {([COUNT].includes(operation_name) ||
        aggregation_type?.value === COUNT) && (
        <Grid item>
          <InputLabel className={inputLabel}>
            Unique&nbsp;
            <Tooltip
              title={
                'Select yes to identify and count the occurrence of unique values only. Selecting no will identify the count of all vales in the selected extract column.'
              }
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconInfo />
            </Tooltip>
          </InputLabel>
          <Select
            fullWidth
            variant="outlined"
            style={{ height: '48px', borderRadius: '8px' }}
            value={unique ? 'true' : 'false'}
            onChange={(e: any) => onChange('unique', e.target.value === 'true')}
          >
            <MenuItem value={'true'}>Yes</MenuItem>
            <MenuItem value={'false'}>No</MenuItem>
          </Select>
        </Grid>
      )}

      {/* Below UI renders only when operation/label is filter */}
      {operation_name === FILTER && (
        <>
          <Grid item>
            <InputLabel className={inputLabel}>Operator</InputLabel>
            <EBAutoComplete
              options={FILTER_OPERATORS.filter(
                (option: Option) =>
                  !Array.isArray(column_name) &&
                  option.data_types?.includes(column_name?.data_type || '')
              )}
              getOptionLabel={(option: Option) => option.title || ''}
              forcePopupIcon={true}
              height={48}
              size="small"
              value={operation_formula || null}
              onChange={(e, value: Option) =>
                onChange('operation_formula', value)
              }
            />
          </Grid>
          <Grid item>
            <InputLabel className={inputLabel}>
              Filter Column&nbsp;
              <Tooltip
                title={`Select 'Custom Input' option or the second field to be used in the operation. If 'Custom Input' has been selected a new input box called 'Filter Value' is shown in which specific user criteria should be included such as a value or text`}
                placement="right"
                classes={{ tooltip: classes.tooltip }}
              >
                <IconInfo />
              </Tooltip>
            </InputLabel>
            <EBAutoComplete
              options={[
                { title: 'Custom Input' },
                ...options.filter(
                  (option: Option) =>
                    !Array.isArray(column_name) &&
                    option.data_type === column_name?.data_type
                ),
              ]}
              getOptionLabel={(option: Option) => option.title || ''}
              forcePopupIcon={true}
              height={48}
              size="small"
              value={operation_value || null}
              onChange={(e, value: Option) =>
                onChange('operation_value', value)
              }
            />
          </Grid>
          {operation_value?.title === 'Custom Input' && (
            <>
              <Grid item>
                <InputLabel className={inputLabel}>Data Type</InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder=""
                  color="secondary"
                  className={customInput}
                  value={
                    !Array.isArray(column_name) ? column_name?.data_type : ''
                  }
                  disabled
                />
              </Grid>
              <Grid item>
                <InputLabel className={inputLabel}>Filter Value</InputLabel>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder=""
                  type={getInputType()}
                  color="secondary"
                  className={customInput}
                  value={operation_value?.value}
                  onChange={(e: any) =>
                    onChange('operation_value', {
                      ...operation_value,
                      value: e.target.value,
                      ...(!Array.isArray(column_name) && {
                        data_type: column_name?.data_type,
                      }),
                    })
                  }
                />
              </Grid>
            </>
          )}
        </>
      )}

      {/* Below UI renders only when operation/label is outside working days */}
      {operation_name === OUTSIDE_WORKING_HOURS && (
        <>
          <Grid item>
            <InputLabel className={inputLabel}>Start Time</InputLabel>
            <TextField
              variant="outlined"
              type="time"
              fullWidth
              className={customInput}
              InputLabelProps={{
                shrink: true,
              }}
              value={start_time}
              onChange={(e) =>
                onTimeChangeHandler('start_time', e.target.value)
              }
            />
          </Grid>
          <Grid item>
            <InputLabel className={inputLabel}>End Time</InputLabel>
            <TextField
              variant="outlined"
              type="time"
              fullWidth
              className={customInput}
              InputLabelProps={{
                shrink: true,
              }}
              value={end_time}
              onChange={(e) => onTimeChangeHandler('end_time', e.target.value)}
            />
          </Grid>
        </>
      )}
    </DaaOperationComponent>
  )
}

DaaTransform.propTypes = {
  unique: PropTypes.bool,
}

DaaTransform.defaultProps = {
  unique: false,
}

export default DaaTransform
