import React, { useMemo } from 'react'
import {
  getConnectedEdges,
  Handle,
  NodeProps,
  useNodeId,
  useStore,
} from 'reactflow'

import { Grid, Button, Tooltip, Typography } from '@mui/material'
import { ReactComponent as GreenCheckIcon } from './GreenCheck.svg'
import classes from './react-flow-node.module.css'
import { WEEKDAYS } from '@engine-b/integration-engine/features/audit-analytics'

const selector = (s: any) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
  nodes: s.nodes,
})

export enum Position {
  Left = 'left',
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
}

export const ExtractNode = ({ selected, data }: NodeProps) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          padding: 0,
          margin: 'auto',
        }}
      >
        <Tooltip
          title={
            <>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                INPUT FILE
              </Typography>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                Ingestion Name: {data?.ingestion?.name}
              </Typography>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                CDM File Name: {data?.file_name}
              </Typography>
            </>
          }
        >
          <div
            className={classes['extractDiv']}
            style={
              selected
                ? {
                    boxShadow: '2px 4px 6px 2px #05817a80',
                    transition: 'box-shadow 0.2s ease-in-out',
                  }
                : {}
            }
          >
            <Button
              className={classes['roundBtn']}
              variant="text"
              startIcon={data?.icon}
            ></Button>
          </div>
        </Tooltip>
      </Grid>
      {data?.output_columns?.length > 0 ? (
        <GreenCheckIcon
          style={{ position: 'absolute', right: '-5px', top: '-5px' }}
        />
      ) : null}
      <Handle
        type="source"
        position={Position.Right}
        style={{
          height: '10px',
          width: '10px',
          left: '35px',
          background: '#395c74',
        }}
      />
    </>
  )
}
export const FileNode = ({ data, selected }: NodeProps) => {
  const { nodeInternals, edges, nodes } = useStore(selector)
  const nodeId = useNodeId()

  const isHandleConnectable = useMemo(() => {
    const node = nodeInternals.get(nodeId)
    const connectedEdges = getConnectedEdges([node], edges)

    return connectedEdges.filter((x) => x.target === nodeId).length < 1
  }, [nodeInternals, nodes, edges, nodeId])

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          padding: 0,
          margin: 'auto',
        }}
      >
        <Tooltip
          title={
            <>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                OUTPUT FILE
              </Typography>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                New File Name: {data?.file_name && data?.file_name + '.csv'}
              </Typography>
            </>
          }
        >
          <div
            className={classes['fileDiv']}
            style={
              selected
                ? {
                    boxShadow: '2px 4px 6px 2px #05817a80',
                    transition: 'box-shadow 0.2s ease-in-out',
                  }
                : {}
            }
          >
            <Button
              className={classes['fileBtn']}
              style={{ backgroundColor: '#00B2A9', color: 'white' }}
              variant="text"
              startIcon={data?.icon}
            ></Button>
          </div>
        </Tooltip>
      </Grid>
      {data?.input_columns?.length > 0 &&
      data?.file_name?.trim() &&
      !data?.loadFileNameError ? (
        <GreenCheckIcon
          style={{ position: 'absolute', right: '-5px', top: '-5px' }}
        />
      ) : null}
      <Handle
        isConnectable={isHandleConnectable}
        type="target"
        position={Position.Left}
        style={{
          height: '10px',
          width: '10px',
          right: '35px',
          background: '#395c74',
        }}
      />
    </>
  )
}

export const TransformNode = ({ data, selected }: NodeProps) => {
  const { nodeInternals, edges, nodes } = useStore(selector)
  const nodeId = useNodeId()
  let columnValues: any = []

  const isHandleConnectable = useMemo(() => {
    const node = nodeInternals.get(nodeId)
    const connectedEdges = getConnectedEdges([node], edges)

    return connectedEdges.filter((x) => x.target === nodeId).length < 1
  }, [nodeInternals, nodes, edges, nodeId])

  const isFilterCustom = (data: any) => {
    return data?.operation_value?.title === 'Custom Input'
  }

  switch (data?.operation_name) {
    case 'bkd':
      columnValues = [
        {
          label: 'Backdating Column',
          value: data?.bkd_column?.title,
        },
        data.is_bkd_custom && {
          label: 'Value',
          value: data?.bkd_column?.value ?? data?.bkd_column,
        },
        {
          label: 'Column',
          value: data?.column_name?.title,
        },
      ]
      break
    case 'count':
      columnValues = [
        {
          label: 'Column',
          value: data?.column_name?.value ?? data?.column_name,
        },
        { label: 'Is Unique', value: data?.unique?.toString() },
      ]
      break
    case 'delete_column':
      columnValues = [
        {
          label: 'Column(s)',
          value: data?.column_name
            ?.map((col: any) => col.value || col)
            .join(', '),
        },
      ]
      break
    case 'filter':
      columnValues = [
        {
          label: 'Column',
          value: data?.column_name?.value,
        },
        { label: 'Operation', value: data?.operation_formula?.title },
        {
          label: 'Filter Column',
          value: data?.operation_value?.title,
        },
        isFilterCustom(data) && {
          label: 'Value',
          value: data?.operation_value?.value,
        },
      ]
      break
    case 'group_by':
      columnValues = [
        {
          label: 'Column',
          value: data?.column_name?.title,
        },
        {
          label: 'Aggregator',
          value:
            typeof data?.aggregation_type === 'string'
              ? data?.aggregation_type?.toUpperCase()
              : data?.aggregation_type?.title,
        },
        {
          label: 'Group By Column(s)',
          value: data?.group_by?.map((grp: any) => grp?.title).join(', '),
        },
      ]
      if (data?.aggregation_type?.value === 'count') {
        columnValues.push({
          label: 'Is Unique',
          value: data?.unique?.toString(),
        })
      }
      break
    case 'multiple_calculation':
      columnValues = [
        { label: 'Expression', value: data?.data?.expression },
        {
          label: 'Columns',
          value:
            typeof data?.data?.column === 'object' &&
            Object?.keys(data?.data?.column)
              ?.map((key) => `${key}: ${data?.data?.column[key]}`)
              .join(', '),
        },
      ]
      break
    case 'net':
      columnValues = [
        {
          label: 'DCI Column',
          value: data?.dci_column?.title,
        },
        {
          label: 'Column',
          value: data?.column_name?.title,
        },
      ]
      break
    case 'nwd':
      columnValues = [
        {
          label: 'WeekDays',
          value: WEEKDAYS.filter((day) => data?.weekdays?.includes(day.id))
            .map((w) => w.label)
            .join(', '),
        },
        { label: 'Custom Dates', value: data?.custom_dates?.join(', ') },
        {
          label: 'Column',
          value: data?.column_name?.title,
        },
      ]
      break
    case 'owh':
      columnValues = [
        {
          label: 'Column',
          value: data?.column_name?.title,
        },
        { label: 'Start Time', value: data?.start_time },
        { label: 'End Time', value: data?.end_time },
      ]
      break
    default:
      columnValues = [
        {
          label: 'Column',
          value: data?.column_name?.title,
        },
      ]
      break
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          padding: 0,
          margin: 'auto',
        }}
      >
        <Tooltip
          title={
            <>
              <Typography sx={{ fontSize: 14 }} gutterBottom>
                {data?.operation_name?.toUpperCase()}
              </Typography>
              {!['duplicate'].includes(data?.operation_name) &&
                columnValues?.map((row: any) => (
                  <React.Fragment key={row.value}>
                    {row && (
                      <Typography sx={{ mb: 1.5 }}>
                        {row.label}: {row.value}
                      </Typography>
                    )}
                  </React.Fragment>
                ))}
            </>
          }
        >
          <div
            className={classes['buttonDiv']}
            style={
              selected
                ? {
                    boxShadow: '2px 4px 6px 2px #05817a80',
                    transition: 'box-shadow 0.2s ease-in-out',
                  }
                : {}
            }
          >
            <Button
              className={classes['roundBtn']}
              variant="text"
              startIcon={data?.icon}
            ></Button>
          </div>
        </Tooltip>
      </Grid>
      {data?.input_columns?.length > 0 && data?.output_columns?.length > 0 ? (
        <GreenCheckIcon
          style={{ position: 'absolute', right: '-5px', top: '-5px' }}
        />
      ) : null}
      <Handle
        type="source"
        position={Position.Right}
        style={{
          height: '10px',
          width: '10px',
          left: '35px',
          background: '#395c74',
        }}
      />
      <Handle
        type="target"
        isConnectable={isHandleConnectable}
        position={Position.Left}
        style={{
          height: '10px',
          width: '10px',
          right: '35px',
          background: '#395c74',
        }}
      />
    </>
  )
}
