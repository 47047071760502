import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

type Colors = 'primary' | 'secondary'
type Variant = 'filled' | 'outlined' | 'standard'
type InputTypes = 'text' | 'number' | 'file' | 'password' | 'date' | 'email'
/* eslint-disable-next-line */
export interface EBInputProps {
  color?: Colors
  fullWidth?: boolean
  height?: string
  placeholder?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: InputTypes
  value?: any
  variant: Variant
  width?: string
  error?: boolean
  helperText?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '8px',
    '& .MuiInputBase-root': {
      '&:not(.MuiInputBase-colorSecondary) .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
      '&.MuiInputBase-colorSecondary .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.light,
      },
      height: '44px',
      '& .MuiOutlinedInput-input, .MuiFilledInput-input': {
        padding: '6px 16px',
      },
    },
    '& .MuiInputBase-input': {
      minWidth: '140px',
    },
  },
}))

export function EBInput(props: Readonly<EBInputProps>) {
  const { root } = useStyles()

  return (
    <TextField
      {...props}
      className={root}
      style={{
        width: props.fullWidth ? '' : props.width,
        height: props.height,
      }}
      data-testid="eb-input"
    />
  )
}

EBInput.propTypes = {
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
}

EBInput.defaultProps = {
  color: 'primary',
  width: 'auto',
  height: '44px',
  placeholder: 'Enter Name',
  variant: 'outlined',
  fullWidth: false,
  type: 'text',
  error: false,
  helperText: '',
}

export default EBInput
