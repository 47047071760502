import {
  IconButton,
  TextField,
  useTheme,
  useMediaQuery,
  makeStyles,
  Checkbox,
  Tooltip,
  Typography,
  Grid,
} from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { Select, AutoSuggestField, AutoSuggestFieldFilters, EBTable } from '@engine-b/shared/components'
import { green, red } from '@material-ui/core/colors'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { ReactComponent as IconRobot } from '../../assets/IconRobot.svg'
import CancelIcon from '@material-ui/icons/Cancel'
import {
  useIEDispatch,
  useIESelector,
  getGroupingSuggestions,
  updateRow,
  updateRowStatus,
  RootState,
  isLoading
} from '@engine-b/integration-engine/data/state/redux'
import { createSelector } from '@reduxjs/toolkit'
import { useApolloClient } from '@apollo/client'
import {AccountTypesFilterResetValue, AccountTypesFilterResetLabel} from './customGroupingConstants'

const emptyTableMessage = <>
  Please use the Existing Groupings <br /> Or <br /> Upload CDM file
</>

export const groupingsOptionSelector = createSelector(
  (state: RootState) => state.customGrouper.groupingOptions,
  (groupingOptions) => groupingOptions
)

const dependanntFieldsHandler = (fieldName = '') => {
  let additionalData = {}
  if (fieldName === 'accountTypeId') {
    additionalData = {
      mapped: 'no',
      accountSubTypeId: -1,
      fsCaptionId: -1,
      accountNameId: -1,
    }
  } else if (fieldName === 'accountSubTypeId') {
    additionalData = {
      mapped: 'no',
      fsCaptionId: -1,
      accountNameId: -1,
    }
  } else if (fieldName === 'fsCaptionId') {
    additionalData = {
      mapped: 'no',
      accountNameId: -1,
    }
  }
  return additionalData
}

const CustomGroupingTable = ({
  autoAssign,
  getTotalSelectedRows,
  loading,
  entityFilterOptions,
  isQuickGrouping,
}) => {
  const dispatch = useIEDispatch()
  const groupingDetails = useIESelector(
    (state) => state.customGrouper?.groupingDetails
  )
  const groupingSuggestions = useIESelector(
    (state) => state.customGrouper?.groupingSuggestions
  )

  const rows = useMemo(() => groupingDetails?.groupingCodes || [], [groupingDetails])
  const totalRowSelected = groupingDetails?.groupingCodes.filter((item) => item?.selected === true)
  const [alteredGroupingOptions, setAlteredGroupingOptions] = useState({});
  const [groupingRecommendations, setGroupingRecommendations] = useState({});
  const [hasErrors, setHasErrors] = useState(false);
  const [filterByAccountTypes, setFilterByAccountTypes] = useState([{
    label: AccountTypesFilterResetLabel, value: AccountTypesFilterResetValue
  }])

  const [recordsToShow, setRecordsToShow] = useState(5);
  const groupingOptions = useIESelector(groupingsOptionSelector)
  const [checkState, setCheckState] = useState('unChecked')
  const client = useApolloClient()

  const categories = ["accountTypes","accountSubTypes","fsCaptions","accountNames"]
  const groupingInputLimit = 4;

  const transformGroupingOptions = () => {
    /**
    * Summary. Transforms the current groupingOptions object into a different data structure.
    * 
    * Description. The current groupingOptions data structure makes it difficult for the user to see
    * the full hierarchy/history of each option available to them. This function transforms every option to
    * include a full history as well as other useful data.
    */

    let unaOptions = {...groupingOptions}
    let aOptions = {};

    Object.keys(unaOptions).forEach((group) => {
         if (group != "accountTypes") {
          aOptions[group] = {};
          Object.keys(unaOptions[group]).forEach((subGroup: any) => {
            aOptions[group][subGroup] = [];
            unaOptions[group][subGroup].forEach((option: any) => {
              let aOption = {...option};
              aOption["group"] = group;
              aOption["groupID"] = subGroup;
              aOption["completeHistory"] = [];
              aOption["isRecommended"] = false;
              aOptions[group][subGroup].push(aOption);
            })
          })
         } else {
            aOptions[group] = [];
            unaOptions[group].forEach((option: any) => {
              let aOption = {...option};
              aOption["group"] = group;
              aOption["groupID"] = null;
              aOption["completeHistory"] = [option.label];
              aOption["isRecommended"] = false;
              aOptions[group].push(aOption);
             })
         }
    })
    return aOptions;
  }

  useEffect(() => {
    const aOptions = transformGroupingOptions()
    setAlteredGroupingOptions(alteredGroupingOptions => ({ ...alteredGroupingOptions, ...aOptions}));
  }, [groupingOptions])

  useEffect(() => {
    dispatch(isLoading(true))
    const groupingCodes = groupingDetails?.groupingCodes.map((gc) => ({
      nominalCode: gc.nominalCode?.toString(),
      glAccountName: gc.glAccountName || null,
    }))
    if(groupingCodes) dispatch(getGroupingSuggestions({ client, grouping: groupingCodes }))
    dispatch(isLoading(false))
  }, [groupingDetails?.groupingCodes])

  // useEffect(() => {
  //   dispatch(isLoading(groupingDetails?.groupingCodes.length > 0 && entityFilterOptions.length <= 0))
  // }, [entityFilterOptions])

  useEffect(() => {
    setGroupingRecommendations(groupingSuggestions);
  }, [groupingSuggestions])

  useEffect(() => {
   setBacktrackingChoices(alteredGroupingOptions);
  }, [alteredGroupingOptions])

  const defineBackTrack = (option, arr = []) => {
    /**
    * Summary. Creates the hierarchy/history for each option.
    * 
    * Description. Defines the entire back history of each option so the user can more easily pick which option
    * better suits their interest.
    *
    * @param {Object}  option
    * @param {Array}   arr
    */

    const prevGroupDict:object = {
      accountTypes: null,
      accountSubTypes: "accountTypes",
      fsCaptions: "accountSubTypes",
      accountNames: "fsCaptions", 
    }
    const prevGroup = prevGroupDict[option.group]

    if (prevGroup === null) {
      return arr = []
    };

    Object.keys(alteredGroupingOptions[prevGroup]).forEach((prevSubgroup) => {
      if(Array.isArray(alteredGroupingOptions[prevGroup][prevSubgroup])) {
        const prevOption = alteredGroupingOptions[prevGroup][prevSubgroup].filter((prevOption => prevOption.value == option.groupID))[0];
        if (prevOption != undefined) {
          arr.unshift(prevOption)
          return defineBackTrack(prevOption, arr)
        } 
      } else {
        if (alteredGroupingOptions[prevGroup][prevSubgroup].value == option.groupID) {
          arr.unshift(alteredGroupingOptions[prevGroup][prevSubgroup])
          return defineBackTrack(alteredGroupingOptions[prevGroup][prevSubgroup], arr)
        };
      }
    })
    return arr;

  }

  const setBacktrackingChoices = (options) => {
    /**
    * Summary. Goes through each object in the groupingOptions state and alters them to display history.
    * 
    * Description. At the moment we are using a string separated by asterisks to display the history of each option,
    * this is by no means optimal and should be improved on IE-537.
    *
    * @param {Object}  options
    */
    Object.keys(options).forEach((group) => {
       Object.keys(options[group]).forEach((subGroup: any) => {
          if(Array.isArray(options[group][subGroup])) {
            options[group][subGroup].forEach((option) => {
              let completeHistoryArr = defineBackTrack(option);
              let completeHistoryStr = [];
              let completeHistoryValues = [];
              completeHistoryArr.forEach((hist) => {
                completeHistoryStr.push(hist.label);
                completeHistoryValues.push(hist.value);
              })
              completeHistoryStr.push(option.label);
              completeHistoryValues.push(option.value);
              option.completeHistory =  completeHistoryStr
              option.completeHistoryValues = completeHistoryValues;
            })
          }
       })
    })
  }

  const theme = useTheme()

  const handleRowDataChange = (
    fieldValue,
    { fieldName, rowIndex = -1, row, reason = "change", glMapNumber = '' }
  ) => {
    if (totalRowSelected?.length > 0 && totalRowSelected.includes(row) && fieldName !== 'selected' && reason != "autoAssign") {
      totalRowSelected?.map((row, rowIndex) => {
        dispatch(
          updateRow({
            row,
            rowIndex,
            fieldData: {
              [fieldName]: fieldValue,
              ...dependanntFieldsHandler(fieldName),
            },
            autoAssign,
            glMapNumber
          })
        )
      })
    } else {
        dispatch(
          updateRow({
            row,
            rowIndex,
            fieldData: {
              [fieldName]: fieldValue,
              ...dependanntFieldsHandler(fieldName),
            },
            autoAssign,
            glMapNumber
          })
        )
    }
  }

  const handleFilterCheckbox = (val) => {
    getFilteredRecords?.forEach((item, index) => {
      dispatch(
        updateRow({
          row: item,
          rowIndex: index,
          fieldData: { selected: val },
        })
      )
    })

    if (getFilteredRecords?.length > 0) {
      checkState === 'unChecked'
        ? setCheckState('blackChecked')
        : setCheckState('unChecked')
    }
  }

  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'))

  const [filterState, setFilterState] = useState<any>({
    nominalCode: '',
    glAccountName: '',
    accountType: AccountTypesFilterResetValue,
    mapped: 'all',
    category: -1,
    entity: 'all'
  })

  useEffect(() => {
    if (groupingDetails?.groupingCodes?.length > 0) {
      focusInput(null);
    }
  }, [groupingDetails?.groupingCodes?.length])

  const filterByNominalCode = (searchParam, nominalCode) => {
    const regWh = new RegExp(/^\*.*\*$/g)
    let regStart = new RegExp(/^\*.*$/g)
    let regEnd = new RegExp(/^.*\*$/g)
    let nominalCodeReg = filterState.nominalCode.replace(/\*/g, '').replace(/\?/g, '.')
    if (regWh.test(searchParam)) {
      nominalCodeReg = new RegExp(`^${nominalCodeReg}$`)
      return nominalCodeReg
        ? nominalCodeReg
          .test(nominalCode)
        : true
    } else if (regStart.test(searchParam)) {
      nominalCodeReg = new RegExp(`^${nominalCodeReg}`)
      return nominalCodeReg
        ? nominalCodeReg
          .test(nominalCode)
        : true
    } else if (regEnd.test(searchParam)) {
      nominalCodeReg = new RegExp(`${nominalCodeReg}$`)
      return nominalCodeReg
        ? nominalCodeReg
          .test(nominalCode)
        : true
    }
    return false
  }
  
  const updateCategoryLevel = (level: number) => {
    let newLevel = level;
    newLevel++
    setFilterState({ ...filterState, ['category']: newLevel })
  }

  let inputRefs = [];
  const setRef = (ref) => {
    if (ref != null) inputRefs.push(ref);
  };

  const focusInput = (
    id = null
    ) => {
      if (id != null ) {
        inputRefs[(id % recordsToShow)+1]?.focus();
      } else {
        inputRefs[0]?.focus();
      }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    let level = filterState.category;
    
    if (event.code == "Tab" && !event.shiftKey) {
      event.preventDefault()
      if (level >= -1 && level < 3){
        level++
      }
    }

    if (event.shiftKey && event.code == "Tab") {
      event.preventDefault()
      if(level > -1) {
        level--
      }
    }
      
    setFilterState({ ...filterState, 'category': level })
  };

  const setPlaceholderText = (mapped, cat) => {
    let arr = [cat.accountType, cat.accountSubType, cat.fsCaption, cat.accountName];
    let categoryLevel = arr.filter((cat) => (cat != -1 && cat != undefined)).length;
    let placeholder;
    
    if (mapped) {
      placeholder = '';
    } else {
      if(filterState.category > 0 && categoryLevel < filterState.category) {
        placeholder = `Choose ${categories[filterState.category]}`
      } else {
        placeholder = `Choose ${categories[categoryLevel]}`
      }
    }

    return placeholder;
  }

  const clearOption = (val, rowIndex) => {
    const paramsValue = {
      fieldName: val, 
      rowIndex: rowIndex, 
      row: groupingDetails?.groupingCodes[rowIndex]
    }
    handleRowDataChange(-1, paramsValue);
  }

  const removeOption = (event: React.ChangeEvent<{}>, selected: number, rowIndex: number) => {
    const catValue = ["accountTypeId", "accountSubTypeId", "fsCaptionId", "accountNameId"];
    const catLabel = ["accountType", "accountSubType", "fsCaption", "accountName"];

    catValue.forEach((val, index) => {
      if (index >= selected) {
        clearOption(val, rowIndex);
      } 
    });

    catLabel.forEach((val, index) => {
      if (index >= selected) {
        clearOption(val, rowIndex);
      }
    });
  }

  const getFilteredRecords = useMemo(() => {
    let filteredRows = [];

    if (
      filterState.nominalCode !== '' ||
      filterState.accountName !== '' ||
      filterState.mapped !== 'all' ||
      filterState.entity !== 'all'
    ) {
      const resp = rows.filter((row, index) => {
        const mappedFlag = filterState.mapped === 'all' ? true : filterState.mapped === row.mapped
        const entityFlag = filterState.entity === 'all' ? true : filterState.entity === row.entity
        const filterNominal = new RegExp(/\*/g)
        let nominalCodeFlag = true
        if (filterNominal.test(filterState.nominalCode)) {
          return filterByNominalCode(filterState.nominalCode, row.nominalCode)
        } else {
          nominalCodeFlag = filterState.nominalCode
            ? row.nominalCode
              .toString()
              .toLowerCase()
              .indexOf(filterState.nominalCode.toLowerCase()) > -1
            : true
          const glAccountNameFlag = filterState.glAccountName
            ? row.glAccountName
              .toLowerCase()
              .indexOf(filterState.glAccountName.toLowerCase()) > -1
            : true
          return mappedFlag && glAccountNameFlag && nominalCodeFlag && entityFlag
        }
      })
      filteredRows = resp
    }

    if (filterState.accountType !== AccountTypesFilterResetValue) {
      const filteredRowsByAccountType = filteredRows.filter((row, index) => {
        return row.accountType === filterState.accountType
      })
      filteredRows = filteredRowsByAccountType
    }      
   
    return filteredRows
  }, [filterState, rows])

  useEffect(() => {
    if (
      getFilteredRecords?.every(
        (v: any) => v?.selected === false || v?.selected === undefined
      )
    ) {
      setCheckState('unChecked')
    } else if (getFilteredRecords?.every((v: any) => v?.selected === true)) {
      setCheckState('blackChecked')
    } else {
      setCheckState('greyChecked')
    }
  },[getFilteredRecords])

  const handleFilterChange = ({ fieldName = '', fieldValue = '' }) => {
    handleFilterCheckbox(false)
    if (fieldName === 'mapped') {
      setFilterState({ ...filterState, [fieldName]: fieldValue })
    } else {
      setFilterState({ ...filterState, mapped: filterState.mapped, [fieldName]: fieldValue })
    }
  }

  const clearCategoryFilter = () => {
    setFilterState({ ...filterState, ['category']: -1 });
  }

  useEffect(() => {
    if (groupingDetails === null) {
      setFilterState({
        nominalCode: '',
        glAccountName: '',
        accountType: AccountTypesFilterResetValue,
        mapped: 'all',
        category: -1,
        entity: 'all'
      })
    }
  }, [groupingDetails])

  useEffect(() => {
    setFilterState({...filterState, category: -1});
  }, [autoAssign])

  useEffect(() => {
    const flagArr = [
      groupingOptions.accountNames === undefined, 
      groupingOptions.fsCaptions === undefined, 
      groupingOptions.accountTypes === undefined, 
      groupingOptions.accountSubTypes === undefined, 
    ]
    setHasErrors(flagArr.filter((group) => (group === true)).length > 0);
  }, [groupingOptions])

  const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
  const [recommendations, setRecommendations] = useState([]);

  const getAllOptions = (alteredGroupingOptions, level = -1) => {
    let all_groups = [
      {'accountTypes': alteredGroupingOptions?.accountTypes},
      alteredGroupingOptions?.accountSubTypes,
      alteredGroupingOptions?.fsCaptions,
      alteredGroupingOptions?.accountNames,
    ]

    if (level != -1) {
      all_groups = [all_groups[level]]
    } 

    let all_options: any[] = []

    if(all_groups.length > 0) {
      all_groups.forEach((group) => {
        if (group) {
          Object.keys(group).forEach((subgroup) => {
            if(group[subgroup]) {
              all_options.push(...group[subgroup].sort((a,b) => (a.completeHistory[0][0] > b.completeHistory[0][0]) ? 1 : ((b.completeHistory[0][0] > a.completeHistory[0][0]) ? -1 : 0)));
            }
          })
        }
      })
    }

    let sorted_options = []

    categories.forEach((cat) => {
      let curr_group = all_options.filter((opt) => (opt.group === cat))
      let sorted_group = curr_group.sort((a,b) => (a.completeHistory[0][0] > b.completeHistory[0][0]) ? 1 : ((b.completeHistory[0][0] > a.completeHistory[0][0]) ? -1 : 0))
      sorted_options.push(...sorted_group);
    })    

    getOptionsFromSuggestions(sorted_options) 
    setDropdownOptions(sorted_options);
    return sorted_options;
  }

  const getOptionsFromSuggestions = (allOptions) => {
    let options = allOptions
    let recommendations = []
    let arr = groupingSuggestions;
    
    if(arr) {
      arr.forEach((suggestion: any, index: number) => {
        let suggestionGroup = []
        suggestion.recommendations.forEach((label:string) => {
          options.forEach((opt) => {
            if(opt.label === label) {
              let suggestion = {
                ...opt,
                isRecommended: true,
                recommendationNo: suggestionGroup.length + 1
              }
              if(suggestion.group == 'accountNames') suggestionGroup.push(suggestion);
            }
          })
        })
        recommendations.push(suggestionGroup)
      })
    }
    if (process.env.NX_ENABLE_GROUPER_RECOMMENDATIONS === 'true') setRecommendations(recommendations)
  }

  const handleChange = (
    event: any, currOption: any, reason: any, index
  ) => {     
    if(reason == 'clear') {
      removeOption(event, 0, index)
    } else {
        const currOptionValue = currOption[currOption.length-1].completeHistoryValues != undefined 
          ? currOption[currOption.length-1].completeHistoryValues 
          : [currOption[currOption.length-1].value];
        const currOptionLabel = currOption[currOption.length-1].completeHistory != undefined
          ? currOption[currOption.length-1].completeHistory
          : [currOption[currOption.length-1].label];
        const catValue = ["accountTypeId", "accountSubTypeId", "fsCaptionId", "accountNameId"];
        const catLabel = ["accountType", "accountSubType", "fsCaption", "accountName"];
        
        currOptionLabel.forEach((val, i) => {
          const paramsLabel = {
            fieldName: currOptionLabel.length > 0 ? catLabel[i] : catLabel[0], 
            rowIndex: index, 
            row: groupingDetails?.groupingCodes[index],
            reason
          }
          handleRowDataChange(val, paramsLabel);
        })

        currOptionValue.forEach((val, i) => {
          const paramsValue = {
            fieldName: currOptionValue.length > 0 ? catValue[i] : catValue[0], 
            rowIndex: index, 
            row: groupingDetails?.groupingCodes[index],
            reason,
            ...(currOption[i]?.glMapNumber && {
              glMapNumber: currOption[i]?.glMapNumber,
            }),
          }

          handleRowDataChange(val, paramsValue);
        })

        if(currOptionValue.length === 4) focusInput(index);
    }
  };
  
  useEffect(() => {
    getAllOptions(alteredGroupingOptions);
  }, [alteredGroupingOptions, groupingSuggestions, groupingOptions])
  
  useEffect(() => {
    if (alteredGroupingOptions['accountTypes']) {
      setFilterByAccountTypes([
        {label: AccountTypesFilterResetLabel, value: AccountTypesFilterResetValue},
        ...alteredGroupingOptions['accountTypes'].map((opt) => ({"label": opt.label, "value": opt.label}))
      ])
    }
  }, [alteredGroupingOptions['accountTypes']])
  
  useEffect(() => {
    if(autoAssign && recommendations.length > 0) {
      if(totalRowSelected.length > 0) {
        totalRowSelected.forEach((row, index) => {
          if (recommendations[row.index] && recommendations[row.index][0]) handleChange({}, [recommendations[row.index][0]], 'autoAssign', row.index);
        })
      } else {
        groupingDetails?.groupingCodes.forEach((row, index) => {
          if (row.accountTypeId === -1 && recommendations[index] && recommendations[index][0]) handleChange({}, [recommendations[index][0]], 'change', index);
        })
      }
    }
  }, [autoAssign])

  useEffect(() => {
    getTotalSelectedRows(totalRowSelected)
  }, [totalRowSelected])

  const getAllDropdowns = (rowIndex) => {
    let dropdowns = [];
    if(recommendations && recommendations[rowIndex]) {
        dropdowns = recommendations[rowIndex].concat(dropdownOptions)
    } else {
      dropdowns = dropdownOptions
    }

    return dropdowns
  }

  const MappedIcon = (mappedStatus) => {
    switch(mappedStatus) {
      case "AutoAssigned": 
        return <IconRobot style={{ width: '35px', height: '35px', fill: 'black', borderRadius: '50%', padding: '2px' }}/>
      case "yes":
        return <CheckCircleIcon style={{ width: '35px', height: '35px', color: green[500] }} />
      default: 
        return  <CancelIcon style={{ width: '35px', height: '35px', color: red[500] }} />
    }
  }

  const handleMappedClick = (params) => {
    if (totalRowSelected.length > 0) {
      totalRowSelected.forEach((row) => {
        if(row['mapped'] === 'AutoAssigned') {
          dispatch(updateRowStatus({row: row, status: "yes"}));
        }
      })
    }
    if(params?.row['mapped'] === 'AutoAssigned') {
      dispatch(updateRowStatus({row: params?.row, status: "yes"}));
    }
  }
 
  const tableHeadersData = useMemo(() => {
    return [
      {
        title: 'Select',
        value: 'selected',
        width: '60px',
        renderer: function (params) {
          const {
            row: { selected },
          } = params
          return (
            <Tooltip
              arrow
              interactive
              title={`${totalRowSelected?.length || 0} rows selected`}
              placement="bottom-start"
            >
              <Checkbox
                name="selected"
                color="secondary"
                checked={selected ? true : false}
                onChange={(e) =>
                  handleRowDataChange(e?.target?.checked, params)
                }
              />
            </Tooltip>
          )
        },
        filterRenderer: () => (
          <Checkbox
            name="selected"
            style={
              checkState === 'blackChecked'
                ? { color: 'black' }
                : checkState === 'greyChecked'
                  ? { color: 'grey' }
                  : {}
            }
            checked={checkState === 'unChecked' ? false : true}
            onChange={(e) => handleFilterCheckbox(e?.target?.checked)}
          />
        ),
      },
      {
        title: 'Entity',
        value: 'entity',
        width: '200px',
        renderer: function(params) {
          return (
            <Typography
              data-testId="entity-row"
            >
              {params.row.entity ? params?.row?.entity : "" }
            </Typography>
          )
        },
        filterRenderer: () => (
          <Select
            selectProps={{
              style: {
                backgroundColor: 'white',
              },
            }}
            value={filterState.entity}
            items={[
              { label: 'All', value: 'all' },
              ...entityFilterOptions.map((ent) => {
                return { label: ent, value: ent}
              })
            ]}
            onChangeHandler={(value) =>
              handleFilterChange({
                fieldName: 'entity',
                fieldValue: value,
              })
            }
          />
        )
      },
      {
        title: 'GL Account Number',
        value: 'nominalCode',
        width: '60px',
        
        filterRenderer: () => (
          <TextField
            placeholder="Search By Nominal Code"
            variant="outlined"
            value={filterState.nominalCode || ''}
            onChange={(e) =>
              handleFilterChange({
                fieldName: 'nominalCode',
                fieldValue: e.target.value,
              })
            }
            InputProps={{
              style: {
                height: '44px',
                backgroundColor: 'white',
                borderRadius: '8px',
              },
            }}
          />
        ),
        renderer: function (params) {
          return (
            <Typography
              data-testId="nominal-code"
            >
              {params.row.nominalCode === 'nan' ? "-" : params?.row?.nominalCode }
            </Typography>
          )
        }
      },
      {
        title: 'GL Account Name',
        value: 'glAccountName',
        width: '160px',
        filterRenderer: () => (
          <TextField
            placeholder="Search By Account Name"
            variant="outlined"
            value={filterState.glAccountName || ''}
            onChange={(e) =>
              handleFilterChange({
                fieldName: 'glAccountName',
                fieldValue: e.target.value,
              })
            }
            InputProps={{
              style: {
                height: '44px',
                backgroundColor: 'white',
                borderRadius: '8px',
              },
            }}
          />
        ),
      },
      {
        title: 'Mapped',
        value: 'mapped',
        width: '130px',
        renderer: function (params) {
          try {
            if (params.row.mapped) {
              return (
                <IconButton 
                  size="medium" 
                  onClick={() => handleMappedClick(params)} 
                  data-testid="check-btn-custom-grouper"
                >
                  {MappedIcon(params?.row['mapped'])}
                </IconButton>
              )
            }
          } catch (e) {
            return null
          }
        },
        filterRenderer: () => (
          <Select
            selectProps={{
              style: {
                backgroundColor: 'white',
              },
            }}
            value={filterState.mapped}
            items={[
              { label: 'All', value: 'all' },
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
              { label: 'AutoAssigned', value: 'AutoAssigned' },
            ]}
            onChangeHandler={(value) =>
              handleFilterChange({
                fieldName: 'mapped',
                fieldValue: value,
              })
            }
          />
        ),
      },
      {
        title: 'Grouping',
        value: 'Grouping',
        width: '900px',
        colspan: 2,
        renderer: function (params) {
          try {
            if (params.row.mapped) {
              return (
              !hasErrors
              ? 
              <AutoSuggestField
                  key={`autosuggestfield-${params.row.index}`}
                  id={params.row.index}
                  limitTags={groupingInputLimit}
                  textFieldLabel={`Select Groupings`}
                  options={getAllDropdowns(params.row.index)}
                  alteredGroupingOptions={alteredGroupingOptions}
                  inputRef={setRef}
                  values={[
                    groupingDetails?.groupingCodes[params.row.index].accountTypeId,
                    groupingDetails?.groupingCodes[params.row.index].accountSubTypeId,
                    groupingDetails?.groupingCodes[params.row.index].fsCaptionId,
                    groupingDetails?.groupingCodes[params.row.index].accountNameId
                  ].filter((value) => (value != -1))}
                  valuesToDisplay={[
                    groupingDetails?.groupingCodes[params.row.index].accountType,
                    groupingDetails?.groupingCodes[params.row.index].accountSubType,
                    groupingDetails?.groupingCodes[params.row.index].fsCaption,
                    groupingDetails?.groupingCodes[params.row.index].accountName
                  ].filter((value) => (value != -1))}
                  invalid={params?.row['mapped'] === 'no'} 
                  disabled={false}
                  maxWidth='100%'
                  onBackspace={(e, selected) => removeOption(e, selected, params.row.index)}
                  autoAssign={autoAssign ? true : false}
                  categoryFilter={filterState.category}
                  groupingSuggestions={groupingSuggestions}
                  placeholderText={setPlaceholderText(params?.row['mapped'] !== 'no', groupingDetails?.groupingCodes[params.row.index])}
                  onChange={(e, value, reason) => handleChange(e, value, reason, params.row.index)}
                  removeOption={(e, value) => removeOption(e, value, params.row.index)}
                />
                : params.rowIndex === 0 && <span> { !loading ? "We couldn't fetch grouping options. Please try again." : ""}</span>
              )
            }
          } catch (e) {
            return null
          }
        },
        filterRenderer: function () {
          return(
            <Grid container style={{justifyContent : "space-between", alignItems: "center"}}>
              <Grid item>
                <AutoSuggestFieldFilters
                  categories={categories}
                  limitTags={groupingInputLimit}
                  categoryLevel={filterState.category}
                  disabled = {groupingDetails?.groupingCodes == undefined}
                  updateCategoryLevel={(catLevel)=>updateCategoryLevel(catLevel)}
                  handleClose={() => (clearCategoryFilter())}
                />
              </Grid>
            </Grid>
          )
        }
      },
      {
        title: 'Filter by Account Types',
        value: 'null',
        width: '130px',
        filterRenderer: () => (
          <Grid item>
            {filterByAccountTypes 
            ? <Select
              selectProps={{
                style: {
                  backgroundColor: 'white',
                },
              }}
              dataTestId="accountType-filter"
              placeholder='Filter Rows by Account Type'
              value={filterState.accountType}
              items={filterByAccountTypes}
              onChangeHandler={(value) =>
                handleFilterChange({
                  fieldName: 'accountType',
                  fieldValue: value,
                })
              }
            />
            : <></>
          }
          </Grid>
        )
      }
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    smallScreen,
    groupingOptions,
    filterState,
    checkState,
    groupingDetails,
    recommendations,
    hasErrors,
    entityFilterOptions
  ])

  return (
    <div
      style={{display: "flex", flexGrow: 1}}
      onKeyDown={handleKeyDown}
    >
      <EBTable
        filterEnabled={true}
        headers={tableHeadersData}
        rows={getFilteredRecords}
        recordsPerPage={5}
        emptyLabel={groupingDetails || isQuickGrouping ? '' : emptyTableMessage}
        showRecordsPerPage={true}
        changeRecords={(recordsToShow) => setRecordsToShow(recordsToShow)}
      />
    </div>
  )
}

export default React.memo(CustomGroupingTable)
